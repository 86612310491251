
import { Button, ButtonGroup, Divider, Grid, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { BOOKING_TYPE, SELECTED_VEHICLE, SET_SELECTED_BOOKING } from '../../../Redux/constants/bookingConstants'
import Vehicle from './Vehicle'

const MyTransfer = () => {

    const { vehicleAvailability } = useSelector(state => state.mytransfer)


    


    return (
        

             <Grid container spacing={4}  mt={0}>
                {vehicleAvailability?.transferPriceList && vehicleAvailability?.transferPriceList.map((vehicle, index) => {
                    return(
                        <Grid  xs={12} md={4} id={index}  borderBottom="1px solid " borderColor={"rgba(112, 112, 112, 0.2)"}  paddingBottom={1} >
                            <Vehicle 
                                item={vehicle}
                            />
                        </Grid>
                        
                    )
                })}
            </Grid>
    )
}

export default MyTransfer