import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import {Typography} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Logo from '../assets/images/pal_logo.png'
import { useNavigate } from 'react-router-dom';
import DropDownMenu from './DropDownMenu';
import  { useDispatch } from 'react-redux'
import { RESET_USER } from '../Redux/constants/userConstants';
import { BOOKING_DATA, REMOVE_ORDER_ITEM, RESET_BOOKING } from '../Redux/constants/bookingConstants';
import RegisterMenu from './RegisterMenu';

const HomeTopBar = ({activeMenu}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleNavMenu1 = () => {
    setAnchorElNav(null);
    navigate('/dashboard')
  };
  const handleNavMenu2 = () => {
    setAnchorElNav(null);
    navigate('/wallet')
  };
  const handleNavMenu3 = () => {
    setAnchorElNav(null);
    navigate('/topup')
  };
  const handleNavMenu4 = () => {
    setAnchorElNav(null);
    navigate('/profile')
  };
  const handleNavMenu5 = () => {
    setAnchorElNav(null);
    navigate('/markup')
  };
  const handleNavMenu6 = () => {
    setAnchorElNav(null);
    navigate('/user')
  };
  const handleNavMenu7 = () => {
    setAnchorElNav(null);
    navigate('/role')
  };
  const handleNavMenu8 = async() => {
    setAnchorElNav(null);
    dispatch({
      type: RESET_USER
    })
    await localStorage.removeItem("user")
    navigate('/home')  
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const resetdata = ()=>{
    dispatch({
      type: RESET_BOOKING,
    })
  
  }

  return (
    
    <AppBar position="static" className='top'>
      <Container maxWidth="100%" height="97" width="100%" style={{ paddingLeft: 100, paddingRight: 100 }}>
        <Toolbar disableGutters > 
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <img src={Logo} style={{ width: 90, height: 50 }} onClick={resetdata}/>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } } } style={{ justifyContent:'end'}}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon className='menuicon'/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {/* <span >{activeMenu=='1'?<Typography   className='active'>HOME</Typography>:<Typography className='inactive'>HOME</Typography>}</span>
              <span ><DropDownMenu menuname={"SERVICES"} activeMenu={activeMenu} /></span>
              <span >{activeMenu=='2'?<Typography className='active'>BLOG</Typography>:<Typography className='inactive'>BLOG</Typography>}</span>
              <span ><DropDownMenu menuname={"COMPANY"} activeMenu={activeMenu} /></span>
              <span >{activeMenu=='3'?<Typography  className='active'>CARBON OFFSET</Typography>:<Typography className='inactive'>CARBON OFFSET</Typography>}</span>
              <span ><Typography className='inactive'>CONTACT US</Typography></span> */}
                  <span ><Typography className='inactive' onClick={() => navigate("/signin")}>LOGIN</Typography></span>  
              <span > <Typography style={{marginTop:15}}><RegisterMenu menuname={"REGISTER"} back={true}/></Typography></span>  
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
            }}
          >
            <img src={Logo} style={{ width: 90, height: 50 }} onClick={resetdata}/>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>   
              <span >{activeMenu=='1'?<Typography   className='active'>HOME</Typography>:<Typography className='inactive'>HOME</Typography>}</span>
              {/* <span ><DropDownMenu menuname={"SERVICES"} activeMenu={activeMenu} /></span> */}
              {/* <span >{activeMenu=='2'?<Typography className='active'>BLOG</Typography>:<Typography className='inactive'>BLOG</Typography>}</span> */}
              {/* <span ><DropDownMenu menuname={"COMPANY"} activeMenu={activeMenu} /></span> */}
              {/* <span >{activeMenu=='3'?<Typography  className='active'>CARBON OFFSET</Typography>:<Typography className='inactive'>CARBON OFFSET</Typography>}</span> */}
            
              <span ><Typography className='inactive' onClick={() => navigate("/signin")}>LOGIN</Typography></span>  
              <span > <Typography style={{marginTop:15}}><RegisterMenu menuname={"REGISTER"} back={true}/></Typography></span>  
              {/* <span ><Typography className='inactive'>CONTACT US</Typography></span>   */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default HomeTopBar;
