import React, { useState, useEffect } from 'react'
import WorkFlow from '../../components/WorkFlow'
import Layout from '../Layout'
import Banner from '../../components/Banner'
import './style.scss'
import { Button, Grid, Box, FormGroup, Stack } from '@mui/material'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TopBar from '../../components/TopBar'
import UserList from './UserList'
import AddUser from './AddUser'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { RESET_ERROR } from '../../Redux/constants/userConstants'
const User = ({ head }) => {
    const AddNewUser = () => {
        setAddModalShow(true);
    }

    const dispatch = useDispatch()
    const [addModalShow, setAddModalShow] = useState(false);

    const { newUserSuccess, error, user } = useSelector(state => state.auth)

    useEffect(() => {
        if (newUserSuccess) {
            toast.success("New User added successfully");
            setAddModalShow(false)
            dispatch({
                type: RESET_ERROR
            })
        }

        if (error) {
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [newUserSuccess, error])




    return (

        <Layout head={head}>
            <Stack direction={"row"} justifyContent="space-between" alignItems={"center"}>

                <h1 class='underline'><span style={{ textDecorationLine: 'underline', textDecorationColor: '#B6A269' }}>User</span> Details</h1>
                <Button
                    variant="contained" endIcon={<PersonAddIcon />}
                    style={{ color: '#FFFFFF', backgroundColor: '#333333', borderRadius: 4, fontWeight: 'bold', marginTop: '15px', padding: 5, height: 30 }} onClick={()=>setAddModalShow(true)}>ADD USER</Button>


            </Stack>
            <Box xs={6} md={5} pt={0}>
                <UserList />
            </Box>

            {addModalShow && <AddUser openAddModal={addModalShow} closeAddModal={() => setAddModalShow(false)} />}
        </Layout>
    )
}

export default User
