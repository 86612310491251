import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CarImg from '../../assets/images/IconsNew/sedan-car-front.png'
import AgegroupImg from '../../assets/images/IconsNew/age-group.png'
import BagImg from '../../assets/images/IconsNew/luggage.png'
import Vehicle1Img from '../../assets/images/Vehi1.jpg'
import '../Summary/style.scss'
import { BOOKING_INPUT, BOOKING_TYPE, SELECTED_VEHICLE, SET_SELECTED_BOOKING } from '../../Redux/constants/bookingConstants'
import { useNavigate } from 'react-router-dom'




const Vehicle = ({ item }) => {


    const { bookingData } = useSelector(state => state.booking)

    const navigate = useNavigate()
    const dispatch = useDispatch()



    const selectCurrentVehicle = async () => {

        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'priceplan',
                value: item?.priceplan
            }
        })



        dispatch({
            type: BOOKING_TYPE,
            payload: 'PAL'
        })




        dispatch({
            type: SELECTED_VEHICLE,
            payload: item
        })

        dispatch({
            type: SET_SELECTED_BOOKING,
            payload: {
                booking: bookingData,
                selectedVehicle: item,
                id: 'current'
            }
        })

        navigate("/passengerdetails")
    }



    return (
        <Box display={"flex"} flexDirection="row" mt={1}>
            <div style={{ flex: 0.92 }}>
                <Typography className='nameText'>{item?.vehicleType}</Typography>
                {
                    item?.description ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', marginBottom: 0, alignItems: 'center', marginBottom: 5, marginTop: 5 }}>
                        <img src={CarImg} style={{ width: 25, height: 25 }} />
                        <Typography className='descriptionText' style={{ flexWrap: 'wrap', marginLeft: 30 }}>{item?.description}</Typography>
                    </div> : <div style={{ width: 30, height: 37 }}>
                        <Typography className='descriptionText' style={{ flexWrap: 'wrap', marginLeft: 30 }}>{item?.description}</Typography>
                    </div>
                }
                <Box display={"flex"} flexDirection="row" justifyContent="space-between" >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', marginBottom: 0, flex: 0.5, alignItems: 'center' }}>
                        <img src={AgegroupImg} style={{ width: 25, height: 25 }} />
                        <Typography className='descriptionText' style={{ flexWrap: 'wrap', marginLeft: 30 }}>Max: {item?.passengers}</Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', flex: 0.5, alignItems: 'center' }}>
                        <img src={BagImg} style={{ width: 25, height: 25 }} />
                        <Typography className='descriptionText' style={{ flexWrap: 'wrap', marginLeft: 30 }}>Max: {item?.checkin}</Typography>
                    </div>
                </Box>
                <Box display={"flex"} flexDirection="row" justifyContent="space-between" alignItems={"center"} flex={1} mt={3}>
                    <Typography className='pricetext' flex={0.8}>S${Math.ceil(item?.finaltotal)}</Typography>
                    <div style={{ flex: 0.5 }}>
                        <Button

                            variant="contained"
                            style={{
                                borderRadius: 1,
                                backgroundColor: '#333333',
                                height: 30,
                                borderRadius: 4,
                                padding: 5
                            }}
                            onClick={selectCurrentVehicle}
                            className='buttonText'
                        >
                            Select
                        </Button>
                    </div>

                </Box>
                <Grid xs={12} md={4}>
                    {item?.vehicleImage ? <img src={item?.vehicleImage} style={{ width: 300, height: '100%' }} resizeMode={'contain'} /> : <img src={Vehicle1Img} style={{ width: 300, height: '100%' }} resizeMode={'contain'} />}
                </Grid>
            </div>
            <Divider flexItem orientation='vertical' />
        </Box>
    )
}

export default Vehicle