import React, { Fragment, useEffect } from 'react'
import WorkFlow from '../../components/WorkFlow'
import Layout from '../Layout'
import Banner from '../../components/Banner'
import './style.scss'
import { Card, Typography } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import Person from '@mui/icons-material/Person';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { height } from '@mui/system';
import LocationImg from '../../assets/images/Icons/placeholder.png'
import TimeImg from '../../assets/images/Icons/timetable.png'
import DistanceImg from '../../assets/images/Icons/measure-distance.png'
import { useLocation, useNavigate } from 'react-router-dom';
import DetailsForm from './DetailsForm'
import SelectedVehicle from '../../components/SelectedVehicle'
import TopBar from '../../components/TopBar'
import { useDispatch, useSelector } from 'react-redux'
import { BOOKING_DATA } from '../../Redux/constants/bookingConstants'
import Bookings from '../../components/Bookings'
import AddedVehicle from '../../components/AddedVehicle'
const PassengerDetails = ({ head }) => {
    const location = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { selectedVehicle, bookingData } = useSelector(state => state.booking)

    useEffect(() => {
      if(!selectedVehicle){
        getLocalStorageData()
      }
    }, [bookingData])

    const getLocalStorageData = async() => {
        let data = await localStorage.getItem("booking");
        if(data){
        //     let booking = JSON.parse(data);
        //     dispatch({
        //         type: BOOKING_DATA,
        //         payload: booking
        //     })
            navigate("/summary");
        }
        else{
            navigate("/newbooking");
        }
    }
    
    return (
        <Layout head={head}>
            <WorkFlow activeStep={2} />
            <Grid paddingY={5}  display="flex" flexDirection={{ xs: 'column-reverse',  md: "row"}}>
                <Grid item md={8.5} >
                    <DetailsForm data={selectedVehicle} booking={bookingData} />
                </Grid>
                <Grid item md={3.5} paddingX={{ xs: 3, md: 0 }} paddingBottom={5}>
                    <AddedVehicle />
                </Grid>
            </Grid>
            

        </Layout>
    )
}

export default PassengerDetails
