import moment from "moment";
import { isArray } from "lodash";
import reactotron from '../ReactronConfig';


export function calculateExtra(item, date, type, duration, rate, mode) {
    let extras = 0;
    let extraArray = [];

    if (item?.day) {
        if (mode === "price") {
            return extras;
        }
        else {
            return extraArray;
        }
    }
    else {
        if (isArray(item?.extraChargesNew)) {
            item?.extraChargesNew?.map(extra => {

                let rateType = extra?.rate_type
                //text
                if (extra?.input_type === "text") {

                    if (rateType === "percentage") {
                        extras += type === "hour" ? Math.ceil(((rate * duration) / 100) * parseFloat(extra?.rate)) : Math.ceil(parseFloat(rate) / 100 * parseFloat(extra?.rate))

                        extraArray.push({
                            id: extra?.add_on_item_id,
                            name: extra?.addonitem?.name,
                            price: type === "hour" ? Math.ceil(((rate * duration) / 100) * parseFloat(extra?.rate)) : Math.ceil(parseFloat(rate) / 100 * parseFloat(extra?.rate))
                        })
                    }
                    else if (rateType === "flatrate") {
                        extras += type === "hour" ? Math.ceil(parseFloat(extra?.rate) * duration) : Math.ceil(extra?.rate)
                        extraArray.push({
                            id: extra?.add_on_item_id,
                            name: extra?.addonitem?.name,
                            price: type === "hour" ? Math.ceil(parseFloat(extra?.rate) * duration) : Math.ceil(extra?.rate)
                        })
                    }
                    else if (rateType === "times") {
                        extras += type === "hour" ? Math.ceil((rate * duration) * parseFloat(extra?.rate) - (rate * duration)) : parseFloat(rate * parseFloat(extra?.rate) - rate)
                        extraArray.push({
                            id: extra?.add_on_item_id,
                            name: extra?.addonitem?.name,
                            price: type === "hour" ? Math.ceil((rate * duration) * parseFloat(extra?.rate) - (rate * duration)) : parseFloat(rate * parseFloat(extra?.rate) - rate)
                        })
                    }
                }

                //date
                if (extra?.input_type === "date") {
                    const date1 = moment(extra?.label1, "MM/DD/YYYY");
                    let checkTime = moment(date)
                    let hours = 0;
                    // Check if the time is between the start and end times
                    const isBetween = moment(date1).isSame(checkTime, 'day')

                    if(type === "hour"){
                        for(let i=0; i<duration; i++){
                            if(moment(date1).isSame(moment(date).add(i, 'hour'), 'day')){
                                hours += 1;
                            }
                        }
                    }

                    let rateType = extra?.rate_type

                    // let startDate = moment(date);
                    // let endDate = moment(`${moment(date).format("DD-MM-YYYY")} 23:59:59`, "DD-MM-YYYY HH:mm:ss");

                    

                    // // Calculate the difference between the maximum start time and minimum end time
                    // const duration = moment.duration(endDate.diff(startDate));

                    // // Convert the duration into hours
                    // let hours = duration.asHours();


                    if (isBetween) {
                        if (rateType === "percentage") {
                            extras += type === "hour" ? Math.ceil(parseFloat((rate * hours) / 100) * parseFloat(extra?.rate)) : Math.ceil(parseFloat(rate / 100) * parseFloat(extra?.rate))

                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil(parseFloat((rate * hours) / 100) * parseFloat(extra?.rate)) : Math.ceil(parseFloat(rate / 100) * parseFloat(extra?.rate))
                            })
                        }
                        else if (rateType === "flatrate") {
                            extras += type === "hour" ? Math.ceil(parseFloat(extra?.rate) * hours) : Math.ceil(extra?.rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil(parseFloat(extra?.rate) * hours) : Math.ceil(extra?.rate)
                            })
                        }
                        else if (rateType === "times") {
                            extras += type === "hour" ? Math.ceil((rate * hours) * parseFloat(extra?.rate) - (rate * hours)) : Math.ceil(rate * parseFloat(extra?.rate) - rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil((rate * hours) * parseFloat(extra?.rate) - (rate * hours)) : Math.ceil(rate * parseFloat(extra?.rate) - rate)
                            })
                        }
                    }
                }


                //timerange
                if (extra?.input_type === "timerange") {
                    let endDate, startDate, hours = 0;
                    let startTime, endTime;
                    let label1 = extra?.label1;
                    let label2 = extra?.label2;

                    let date1 = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A")
                    let date2 = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A");
                    let day = moment(date).format("A");

                    


                    if(date1 < date2){
                        if (day?.toLowerCase().includes("pm") && label1?.toLowerCase().includes("am") && label2?.toLowerCase().includes("am")){
                            startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A").add('1', 'day');
                            endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A").add('1', 'day');
                        }
                        else if (day?.toLowerCase().includes("pm") && label1?.toLowerCase().includes("am") && label2?.toLowerCase().includes("pm")){
                            startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A");
                            endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A");
                            
                        }
                        else if (day?.toLowerCase().includes("pm") && label1?.toLowerCase().includes("pm") && label2?.toLowerCase().includes("pm")){
                            startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A");
                            endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A");
                        }
                        if (day?.toLowerCase().includes("am") && label1?.toLowerCase().includes("am") && label2?.toLowerCase().includes("am")){
                            startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A");
                            endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A");
                        }
                        else if (day?.toLowerCase().includes("am") && label1?.toLowerCase().includes("am") && label2?.toLowerCase().includes("pm")){
                            startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A");
                            endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A");
                        }
                        else if (day?.toLowerCase().includes("am") && label1?.toLowerCase().includes("pm") && label2?.toLowerCase().includes("pm")){
                            startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A");
                            endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A");
                        }
                        
                    }
                    else{
                        if (day?.toLowerCase().includes("pm") && label1?.toLowerCase().includes("am") && label2?.toLowerCase().includes("am")){
                            startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A");
                            endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A").add(1, 'day');
                        }
                        else if (day?.toLowerCase().includes("pm") && label1?.toLowerCase().includes("pm") && label2?.toLowerCase().includes("am")){
                            startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A");
                            endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A").add(1, 'day');
                        }
                        else if (day?.toLowerCase().includes("pm") && label1?.toLowerCase().includes("pm") && label2?.toLowerCase().includes("pm")){
                            startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A");
                            endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A").add(1, 'day');
                        }
                        if (day?.toLowerCase().includes("am") && label1?.toLowerCase().includes("am") && label2?.toLowerCase().includes("am")){
                            startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A");
                            endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A").add(1, 'day');
                        }
                        else if (day?.toLowerCase().includes("am") && label1?.toLowerCase().includes("pm") && label2?.toLowerCase().includes("am")){
                            startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A").add(-1, 'day');
                            endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A");
                        }
                        else if (day?.toLowerCase().includes("am") && label1?.toLowerCase().includes("pm") && label2?.toLowerCase().includes("pm")){
                            startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A").add(-1, 'day');
                            endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A");
                        }
                    }

                    reactotron.log({startTime: moment(startTime).format("DD-MM-YYYY hh:mm A"), endTime: moment(endTime).format("DD-MM-YYYY hh:mm A"), date: moment(date).format("DD-MM-YYYY hh:mm A")})
                    

                    // let day = moment(date).format("A");
                    // if (day?.toLowerCase().includes("am")) {
                    //     if (label1?.toLowerCase().includes("am") && label2?.toLowerCase().includes("am")) {
                    //         startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A").add(-1, 'day');
                    //         endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A");
                    //     }
                    //     else if (label1?.toLowerCase().includes("pm") && label2?.toLowerCase().includes("am")) {
                    //         startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A").add(-1, 'day');
                    //         endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A");
                    //     }
                    //     else {
                    //         startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A");
                    //         endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A")
                    //     }
                    // }
                    // else {
                    //     if (label1?.toLowerCase().includes("am") && label2?.toLowerCase().includes("am")) {
                    //         startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A")
                    //         endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A").add(1, 'day');;
                    //     }
                    //     else if (label1?.toLowerCase().includes("pm") && label2?.toLowerCase().includes("am")) {
                    //         startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A")
                    //         endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A").add(1, 'day');
                    //     }
                    //     else {
                    //         startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label1}`, "DD-MM-YYYY hh:mm A")
                    //         endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${label2}`, "DD-MM-YYYY hh:mm A");
                    //     }
                    // }
                    if (type === "hour") {

                        for(let i=0; i<duration; i++){
                            // if(isTimeBetween(moment(date).add(i, 'hour'), startTime, endTime)){
                            //     hours += 1;
                            // }
                            if(moment(date).add(i, 'hour').isBetween(startTime, endTime, null, '[]')){
                                hours += 1;
                            }
                            else if(!moment(date).isSame(moment(date).add(i, 'hour'),'day')){
                                if(moment(date).add(i, 'hour').isBetween(moment(`${moment(date).add(i, 'hour').format("DD-MM-YYYY")} ${extra?.label1}`, "DD-MM-YYYY hh:mm A"),  moment(`${moment(date).add(i, 'hour').format("DD-MM-YYYY")} ${extra?.label2}`, "DD-MM-YYYY hh:mm A"))){
                                    hours += 1;
                                }
                                
                            }
                            //moment(value).isSame(moment(), 'day');
                            // if(moment(date).add(i, 'hour').isBetween(startTime.add(i, 'hour'), endTime.add(i, 'hour'), null, '[]')){
                            //     hours += 1;
                            // }
                        }
                        //reactotron.log({hours, item, extra})
                        // startDate = moment(date);
                        // endDate = moment(date).add(hours, "hour");

                        // // Determine the maximum start time and minimum end time
                        // const maxStartTime = moment.max(startDate, startTime);
                        // const minEndTime = moment.min(endDate, endTime);

                        // // Calculate the difference between the maximum start time and minimum end time
                        // const duration = moment.duration(minEndTime.diff(maxStartTime));

                        // // Convert the duration into hours
                        // hours = duration.asHours();

                        // reactotron.log({hours, item, extra})
                    }

                    let checkTime = moment(date)
                    // Check if the time is between the start and end times
                    let isBetween = false
                    if (type === "hour" && hours > 0) {
                        isBetween = true
                    }
                    else if (type !== "hour") {
                        //reactotron.log({checkTime: moment(checkTime).format("DD-MM-YYYY hh:mm A"), startTime: moment(startTime).format("DD-MM-YYYY hh:mm A"), endTime: moment(endTime).format("DD-MM-YYYY hh:mm A")})
                        isBetween = checkTime.isBetween(startTime, endTime, undefined, '[]');
                    }

                    if (isBetween) {
                        if (rateType === "percentage") {
                            extras += type === "hour" ? Math.ceil(((rate / 100) * hours) * parseFloat(extra?.rate)) : Math.ceil((rate / 100) * parseFloat(extra?.rate))

                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil(((rate / 100) * hours) * parseFloat(extra?.rate)) : Math.ceil((rate / 100) * parseFloat(extra?.rate))
                            })
                        }
                        else if (rateType === "flatrate") {
                            extras += type === "hour" ? Math.ceil(extra?.rate * hours) : Math.ceil(extra?.rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil(extra?.rate * hours) : Math.ceil(extra?.rate)
                            })
                        }
                        else if (rateType === "times") {
                            extras += type === "hour" ? Math.ceil((rate * hours) * parseFloat(extra?.rate) - (rate * hours)) : Math.ceil(rate * parseFloat(extra?.rate) - rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil((rate * hours) * parseFloat(extra?.rate) - (rate * hours)) : Math.ceil(rate * parseFloat(extra?.rate) - rate)
                            })
                        }
                    }
                }


                //daterange
                if (extra?.input_type === "daterange") {
                    let dates = extra?.label1.split('-')
                    let date1 = moment(dates[0].trim(), "DD/MM/YYYY");
                    let date2 = moment(dates[1].trim(), "DD/MM/YYYY");
                    let hours = 0;

                    const isBetween = moment(date).isBetween(date1, date2, null, '[]');


                    if(type === "hour"){
                        for(let i=0; i<duration; i++){
                            if(moment(date).add(i, 'hour').isBetween(date1, date2, null, '[]')){
                                hours += 1;
                            }
                        }
                    }



                    if (isBetween) {
                        if (rateType === "percentage") {
                            extras += type === "hour" ? Math.ceil(parseFloat((rate * hours) / 100) * parseFloat(extra?.rate)) : Math.ceil(parseFloat(rate / 100) * parseFloat(extra?.rate))

                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil(parseFloat((rate * hours) / 100) * parseFloat(extra?.rate)) : Math.ceil(parseFloat(rate / 100) * parseFloat(extra?.rate))
                            })
                        }
                        else if (rateType === "flatrate") {
                            extras += type === "hour" ? Math.ceil(parseFloat(extra?.rate) * hours) : Math.ceil(extra?.rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil(parseFloat(extra?.rate) * hours) : Math.ceil(extra?.rate)
                            })
                        }
                        else if (rateType === "times") {
                            extras += type === "hour" ? Math.ceil((rate * hours) * parseFloat(extra?.rate) - (rate * hours)) : Math.ceil(rate * parseFloat(extra?.rate) - rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil((rate * hours) * parseFloat(extra?.rate) - (rate * hours)) : Math.ceil(rate * parseFloat(extra?.rate) - rate)
                            })
                        }
                    }
                }


                //multidate
                if (extra?.input_type === "multidate") {
                    let dates = extra?.label1.split(',');
                    let isBetween;
                    let hours = 0;
                    dates?.map(da => {
                        if(type === "hour"){
                            for(let i=0; i<duration; i++){
                                if(moment(date).add(i, 'hour').isSame(moment(da, "MM/DD/YYYY"), 'day')){
                                    hours += 1;
                                    isBetween = true
                                }
                            }
                        }
                        else{
                            if (moment(date).isSame(moment(da, "MM/DD/YYYY"), 'day')) {
                                isBetween = true
                            }
                        }
                        
                    })

                    

                    if (isBetween) {
                        if (rateType === "percentage") {
                            extras += type === "hour" ? Math.ceil(((rate * hours) / 100) * parseFloat(extra?.rate)) : Math.ceil((rate / 100) * parseFloat(extra?.rate))

                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil(((rate * hours) / 100) * parseFloat(extra?.rate)) : Math.ceil((rate / 100) * parseFloat(extra?.rate))
                            })
                        }
                        else if (rateType === "flatrate") {
                            extras += type === "hour" ? Math.ceil(parseFloat(extra?.rate) * hours) : Math.ceil(extra?.rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil(parseFloat(extra?.rate) * hours) : Math.ceil(extra?.rate)
                            })
                        }
                        else if (rateType === "times") {
                            extras += type === "hour" ? Math.ceil((rate * hours) * parseFloat(extra?.rate) - (rate * hours)) : Math.ceil(rate * parseFloat(extra?.rate) - rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? Math.ceil((rate * hours) * parseFloat(extra?.rate) - (rate * hours)) : Math.ceil(rate * parseFloat(extra?.rate) - rate)
                            })
                        }
                    }
                }
            })
        }

        if (mode === "price") {
            return extras;
        }
        else {
            return extraArray;
        }
    }

}

function isTimeBetween(momentToCheck, startTime, endTime) {
    const momentCheckTime = momentToCheck.format('HH:mm');
    const startTimeFormatted = startTime.format('HH:mm');
    const endTimeFormatted =endTime.format('HH:mm');

    reactotron.log({momentCheckTime , startTimeFormatted, endTimeFormatted })

    if(startTimeFormatted < endTimeFormatted){
        return momentCheckTime >= startTimeFormatted && momentCheckTime <= endTimeFormatted;
    }
    else{
        return momentCheckTime >= endTimeFormatted && momentCheckTime <= startTimeFormatted;
    }
    
}