import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../Layout'
import './style.scss'
import TransactionsList from './TransactionsList'
import { Button, Grid, Typography, Snackbar, Alert, Stack, Box, Popper, Dialog, ListItem, DialogTitle, List, Divider, Modal, IconButton } from '@mui/material'
import { toast } from 'react-toastify'
import AddTopUp from './AddTopUp'
import { getBanks } from '../../Redux/actions/topupActions'
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import { getProfile } from '../../Redux/actions/profileActions';
import CustomDate from '../../components/CustomDate'
import InfoIcon from '@mui/icons-material/Info';
import customAxios from '../../CustomAxios'
import BorderBox from '../../components/BorderBox'
import CloseIcon from '@mui/icons-material/Close';

const Wallet = ({ head }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const NewTopUp = () => {
        setViewModalShow(true);
    }
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [banks, setBanks] = useState(null)
    const [show, setShow] = useState(false)
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const [viewModalShow, setViewModalShow] = useState(false);
    let id = "popper";
    const { newTopup, error, balance } = useSelector(state => state.topup)
    const { user } = useSelector(state => state.auth)

    const [enableTopup, setEnableTopup] = useState(false)
    const [subUser, setSubUser] = React.useState((user?.parent_id && user?.parent_id > 0) ? true : false);

    useEffect(() => {
        if(user?.parent_id && user?.parent_id > 0){
          let permissions = user?.rolesinfo?.roles_json?.permissions
          let wallet = permissions?.find((item) => item?.menu === 'Wallet');
            if(wallet){
                setEnableTopup(wallet?.create?.selected ? true : false)
            }
            
        }
    }, [user])

    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }

        if (newTopup) {
            setOpen(true)

            setViewModalShow(false);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, newTopup])
    useEffect(() => {
        dispatch(getBanks())
        let id = user?.id
        dispatch(getProfile(id))
    }, [])


    const showBanks = async() => {
        const response = await customAxios.get('adminbanks');
        const data = response.data.data;

        setBanks(data);
        setShow(true)
    }

    const hideDialog = () => {
        setShow(false)
    }

    const { bankLists } = useSelector(state => state.topup)

    return (
        <Layout head={head}>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Topup added Successfully
                </Alert>
            </Snackbar>
            {/* <TopBar activeMenu="2"/>  */}

            <h1 class='underline'><span style={{ textDecorationLine: 'underline', textDecorationColor: '#B6A269' }}>Wallet</span> Details</h1>
            <Grid container spacing={2} pt={1.2} pl={2} >
                <Stack flexDirection={"row"} justifyContent="space-between" width={"100%"}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                        {/* <span><ErrorIcon style={{ width: 20, height: 20 }} /></span> */}
                        <span> <Typography fontSize={{ xs: '12px', md: '12px' }} fontFamily='Nunito' >Top up your wallet for easy checkout!</Typography> </span>
                    </div>

                </Stack>
                <Stack flexDirection={'row'} justifyContent="space-between" alignItems={"center"} width={"100%"}>

                    <Box display={"flex"} flexDirection="row" justifyContent={"center"} alignItems="center" pt={0} pb={2}>
                        <Typography fontSize='20px' fontFamily='Nunito' fontWeight="bold">Current Balance: </Typography>
                        <Stack variant="contained" justifyContent={"center"} alignItems="center"
                            style={{ color: '#333333', fontSize: '20px', fontFamily: 'Nunito', fontWeight: 'bold', padding: 5 }} >
                            S${balance}
                        </Stack>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 30 }}>
                            <div style={{ display: 'flex', justifyContent:'center' }}>
                            {(!subUser || (subUser && enableTopup)) && <Button variant="contained"
                                style={{ color: 'white', backgroundColor: '#333333', borderRadius: 4, padding: 5, height: 30 }} onClick={NewTopUp}
                                className="tabStyle commonButtonSize" >

                                TOP UP </Button>}
                                <InfoIcon 
                                    //style={{ position:'absolute', top: -10 }} 
                                    aria-describedby={id} onClick={showBanks} />
                                <Modal 
                                    onClose={hideDialog} 
                                    open={show}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    <Box display={"flex"} width='40%' padding={4} flexDirection="column">
                                    <div style={{ display: 'flex', padding: '1%', justifyContent: 'flex-end' }}>
                                        <IconButton onClick={hideDialog} style={{ alignSelf: 'flex-end', flex: 0.05 }}>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                    <BorderBox title="BANK DETAILS">
                                    <List sx={{ pt: 0, height: 500, overflowY: 'scroll' }}>
                                        {banks?.map((bank, index) => (
                                            <div>
                                        <ListItem disableGutters key={index} style={{ flexDirection: 'column', flex: 1, width: 400, padding: 5, margin: 5 }}>
                                            {bank?.bank && <Box style={{ display: 'flex', flexDirection: 'row', width: 300 }}>
                                                <label className='formText' style={{ display: 'flex', flex: 0.4 }}>Bank Name</label>
                                                <label className='formText' style={{ display: 'flex', flex: 0.6 }}>: {bank?.bank}</label>
                                            </Box>}
                                            {bank?.account_number && <Box style={{ display: 'flex', flexDirection: 'row', width: 300 }}>
                                                <label className='formText' style={{ flex: 0.4 }}>Account Number</label>
                                                <label className='formText' style={{ flex: 0.6 }}>: {bank?.account_number}</label>
                                            </Box>}
                                            {bank?.bank_code && <Box style={{ display: 'flex', flexDirection: 'row', width: 300 }}>
                                                <label className='formText' style={{ flex: 0.4 }}>Bank Code</label>
                                                <label className='formText' style={{ flex: 0.6 }}>: {bank?.bank_code}</label>
                                            </Box>}
                                            {bank?.branch_code && <Box style={{ display: 'flex', flexDirection: 'row', width: 300 }}>
                                                <label className='formText' style={{ flex: 0.4 }}>Branch Code</label>
                                                <label className='formText' style={{ flex: 0.6 }}>: {bank?.branch_code}</label>
                                            </Box>}
                                            {bank?.swift_code && <Box style={{ display: 'flex', flexDirection: 'row', width: 300 }}>
                                                <label className='formText' style={{ flex: 0.4 }}>Swift Code</label>
                                                <label className='formText' style={{ flex: 0.6 }}>: {bank?.swift_code}</label>
                                            </Box>}
                                            {bank?.more_info && <Box style={{ display: 'flex', flexDirection: 'row', width: 300 }}>
                                                <label className='formText' style={{ flex: 0.4 }}>More Info</label>
                                                <label className='formText' style={{ flex: 0.6 }}>: {bank?.more_info}</label>
                                            </Box>}
                                            {/* <ListItemButton onClick={() => handleListItemClick(email)} key={email}>
                                            <ListItemAvatar>
                                                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                                                <PersonIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={email} />
                                            </ListItemButton> */}
                                            
                                        </ListItem>
                                        <Divider />
                                        </div>
                                        ))}
                                    </List>
                                    </BorderBox>
                                    </Box>
                                </Modal>
                            </div>
                            <Grid display={{ md: 'flex', xs: 'none' }} gap={1} >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography fontFamily={'Nunito'} fontSize={12} >From</Typography>
                                    <CustomDate  changeDate={(value) => setFromDate(value)} />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography  fontFamily={'Nunito'} fontSize={12} >To</Typography>
                                    <CustomDate  changeDate={(value) => setToDate(value)} />
                                </div>
                            </Grid>
                        </div>
                    </Box>
                    
                </Stack>
                <Grid  container  display={{ md: 'none', xs: 'flex' }} py={2}>
                        <Grid item  md={4} style={{ display: 'flex', alignItems: 'center' }}>
                            <CustomDate label={'From'} changeDate={(value) => setFromDate(value)} />
                        </Grid>
                        <Grid item  md={4} style={{ display: 'flex', alignItems: 'center' }}>
                            <CustomDate label={'To'} changeDate={(value) => setToDate(value)} />
                        </Grid>
                    </Grid>
            </Grid>
            <TransactionsList fromDate={fromDate} toDate={toDate} />
            {viewModalShow &&
                <AddTopUp
                    bankLists={bankLists}
                    openModal={viewModalShow}
                    closeModal={() => setViewModalShow(false)}
                />
            }
        </Layout>
    )
}

export default Wallet
