import React, { Fragment } from 'react'
import './style.scss'
import { Card, Typography, IconButton } from '@mui/material'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Modal from '@mui/material/Modal';
import InputField from '../../components/InputField';
import { InputLabel, FormControl } from '@mui/material';
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Rating,
    TextField
} from "@mui/material";
import PlacesInput from '../../components/PlacesInput';
import { useForm } from 'react-hook-form';
import { LoadScript } from '@react-google-maps/api';
import { useDispatch } from 'react-redux';
import { BOOKING_DATA } from '../../Redux/constants/bookingConstants';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import CloseIcon from '@mui/icons-material/Close';
import BorderBox from '../../components/BorderBox';


const AddStop = ({ onAdd }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: '25px',
        border: '1px solid #fff',
        p: 4,
        // height: '671'
    };

    const schema = yup.object({
        stop_name: yup.mixed().required("Stop Name Required")
    }).required();

    const { register, handleSubmit, setValue, reset, control, formState: { errors }, getValues } = useForm({
        resolver: yupResolver(schema),
    });

    const dispatch = useDispatch()


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const onSubmit = async (data) => {
        let booking = JSON.parse(await localStorage.getItem("booking"))
        booking.stops = booking.stops ? [...booking.stops, data] : [data];
        booking.waypoints = booking.waypoints ? [...booking.waypoints, {
            location: {
                lat: data?.stop_name?.latitude,
                lng: data?.stop_name?.longitude
            }
        }] : [{
            location: {
                lat: data?.stop_name?.latitude,
                lng: data?.stop_name?.longitude
            }
        }]
        localStorage.setItem("booking", JSON.stringify(booking));
    
        dispatch({
            type: BOOKING_DATA,
            payload: booking
        })
        reset()
        setOpen(false)
        onAdd()
    }


    return (
        <div>
            <IconButton style={{ padding: 0, margin: 0 }} sx={{ "&:hover": { borderRadius: '15px' } }}>
                <div onClick={handleOpen} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', cursor: 'pointer' }} >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <span>
                            <AddCircleIcon sx={{ display: { xs: 'flex', md: 'flex' }, mr: 1 }} style={{ color: '#B6A269', fontSize: 30 }} />
                        </span>
                        <span>
                            <Typography className='addtext' >Additional Stops?</Typography>
                        </span>

                    </div>
                </div> </IconButton>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={style} width={{ xs: '80%', sm: '80%', md: "55%", lg: "45%", xl: "30%" }}>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        {/* <Typography id="modal-modal-title" className='modalHeader' flex={0.95} display="flex" justifyContent="center">
                            ADD STOPS
                        </Typography> */}
                        <IconButton onClick={handleClose} >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <BorderBox title={"ADD STOPS"}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormGroup >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                                        <PlacesInput
                                            fieldLabel="Stop Name"
                                            fieldName="stop_name"
                                            control={control}
                                            error={errors.stop_name}
                                            width='98%'
                                            fontSize="14px"
                                            onPlaceChange={(value) => setValue("stop_name", value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                                        <InputField
                                            fieldLabel="Add Notes"
                                            fieldName="stopNote"
                                            control={control}
                                            error={errors.stopNote}
                                            width='100%'
                                            fontSize="14px"
                                        />
                                    </Grid>

                                </Grid>

                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 20 }}>
                                    <Button type="submit" variant="filled" size='small' style={{ backgroundColor: '#333333', borderRadius: 0, padding: 2, }}>
                                        <Typography className='buttonText'>ADD</Typography></Button>
                                </div>
                            </FormGroup>
                        </form>
                    </BorderBox>
                </Box>

            </Modal>

        </div>


    )
}

export default AddStop

