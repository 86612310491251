import React from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Divider, Typography } from '@mui/material'
import './style.scss'


const MainStop = ({ address, count, color }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', height: 35 }}>
      <span style={{ position: 'relative' }}>
        <LocationOnIcon style={{ width: 20, height: 20, color: '#B6A269' }} />
        <div style={{ fontSize: 8, width: 10, height: 10, borderRadius: 5, backgroundColor: color, color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', top: 0, right: -3, position: 'absolute' }}>{count}</div>

      </span>
      <Typography style={{ marginLeft: 8, fontFamily: 'Nunito', fontSize: 12, wordBreak: "break-word", minWidth: 200, width: 600 }}  >{address}</Typography>
    </div>
  )
}

export default MainStop