import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './style.scss'
import { Button,Grid ,Typography,FormGroup,Box,Alert,Tab,Tabs} from '@mui/material'
import PropTypes from 'prop-types';
import * as yup from "yup";
import { userRegister } from '../../Redux/actions/userActions';
import GuestsImg from '../../assets/images/Icons/guests.png'
import GuestLogin from './GuestLogin'
import { styled } from '@mui/material/styles';
import GuestRegister from './GuestRegister';
const StyledTabs = styled((props) => (
	<Tabs
		{...props}
		sx={{
			'& .MuiTabs-flexContainer': {
				flexWrap: 'wrap',
			},
		}}
		TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
	/>
))({
	'& .MuiTabs-indicator': {
		display: 'none',
		justifyContent: 'center',
		backgroundColor: 'transparent',
	},
	'& .MuiTabs-indicatorSpan': {
		maxWidth: 50,
		width: '100%',
		backgroundColor: '#B6A269',
	},
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
	({ theme }) => ({
		textTransform: 'none',
		fontFamily: 'nunito',
		fontWeight: '700',
		fontSize: theme.typography.pxToRem(15),
		marginRight: theme.spacing(5),
		borderRadius: '15px',
		width: '100%',
		marginTop: 10,
		backgroundColor: '#F6F6F6',
		color: '#333333',
		'&.Mui-selected': {
			color: '#fff',
			backgroundColor: '#B6A269',
		},

	}),
);

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box >
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const LoginRegister = () => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
		setValue(newValue);
	}; 
        
    return (
        
        <Grid container justifyContent="center" paddingTop={10} >
			
			<GuestLogin  />
            
        </Grid>
       
    )
}

export default LoginRegister
