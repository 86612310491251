import { Box, Button, Typography } from '@mui/material'
import React from 'react'

const BorderBox = ({title, children}) => {
  return (
    <div style={{ padding: 20, paddingTop: 30, border: '1px solid #ccc', position: 'relative', borderRadius: 4, margin: 2, boxShadow: 2 }}>
        <div style={{ backgroundColor: '#333333', color: '#fff', padding: 5, height: 20, position: 'absolute', top: -12 }} >
            <Typography fontFamily={"Nunito"} fontWeight="" fontSize={14} color="#fff">{title}</Typography>
        </div>
        {children}
    </div>
  )
}

export default BorderBox