import BookingDetails from '../Booking/BookingDetails'
import React from 'react'

const BookingForm = () => {

  return (
    <div style={{width:'95%', borderRadius: 0, paddingTop: 20}}>
      <BookingDetails />  
    </div>  
  )
}

export default BookingForm