import React, { useState, useEffect } from 'react'
import {
    CardElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import './style.scss';
import ErrorIcon from '@mui/icons-material/Error';
import { Button, Typography } from '@mui/material';
import axios from '../../CustomAxios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { newBooking } from '../../Redux/actions/bookingActions';
import { RESET_ERROR } from '../../Redux/constants/bookingConstants';
import { toast } from 'react-toastify';
import axiosMyT from 'axios';
import BorderBox from '../../components/BorderBox';


const CardDetails = () => {

    const { orderData, bookingSuccess, couponDetails, couponApplied, bookingType } = useSelector(state => state.booking)
    const { vehicleAvailability } = useSelector(state => state.mytransfer)
    const { user, myTransfer } = useSelector(state => state.auth)
    const { markupLists } = useSelector(state => state.markup)



    const [isPaymentLoading, setPaymentLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const dispatch = useDispatch();

    const navigate = useNavigate()

    useEffect(() => {
        if (bookingSuccess) {
            //localStorage.removeItem("booking");
            setPaymentLoading(false)
            navigate('/done');
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [bookingSuccess])


    const confirmPayment = async () => {
        setPaymentLoading(true)
        let code = ''
        let base_rate = 0;
        let totalPrice = 0;
        let agentMarkup = 0;
        let extra = 0;
        let addOn = 0;
        let tax = 0;
        let toll = 0;
        let adminMarkup = 0;
        let adminDiscount = 0;
        let extraSeat = 0;
        orderData?.map(order => {
            totalPrice += Math.ceil(order?.total_price)
            base_rate += Math.ceil(order?.base_rate);
            agentMarkup += order?.agentMarkup
            adminMarkup += order?.markup;
            adminDiscount += order?.adminDiscount ? order?.adminDiscount : 0;
            tax += Math.ceil(order?.tax)
            extra += Math.ceil(order?.extraCharges)
            extraSeat += order?.extraSeat
            addOn += order?.addonService
            toll += order?.toll
        })

        let couponDiscount = 0;
        if (couponApplied) {
            code = couponDetails?.code
            if (couponDetails?.discount_type === "PERCENTAGE") {
                couponDiscount = Math.ceil(((base_rate + extra + addOn + toll + extraSeat) / 100) * parseFloat(couponDetails?.discount))
            }
            else {
                couponDiscount = Math.ceil(parseFloat(couponDetails?.discount))
            }
   

            // if (user?.role === "agent" && markupLists?.status === 1) {
            //     if (markupLists?.type === "percentage") {
            //         let balanceAmount = base_rate + extra + addOn + toll + adminMarkup - couponDiscount - adminDiscount;
            //         agentMarkup = (balanceAmount / 100) * parseFloat(markupLists?.value)
            //     }
            // }

            if(orderData[0].taxPer > 0 || myTransfer?.tax){
                tax = Math.ceil(((base_rate + extra + addOn + agentMarkup - couponDiscount + toll + adminMarkup - adminDiscount + extraSeat) / 100) * parseFloat(orderData[0].taxPer ? orderData[0].taxPer : myTransfer?.tax))
            }

            
            totalPrice = Math.ceil(base_rate + extra + addOn + agentMarkup + adminMarkup - couponDiscount + tax + toll - adminDiscount + extraSeat)
        }


        //let total = orderData.reduce((total, item) => item.total_price + total, 0)
        let finalP = user?.role === "agent" ? totalPrice - agentMarkup : totalPrice

        let data = {
            amount: finalP * 100,
            currency: orderData[0]?.currency,
            email: user?.email ? user?.email : orderData[0]?.email
        }
        await axios.post('payment', data)
            .then(async ({ data }) => {
                let customerId = data?.data?.customer

          
                const { paymentIntent, error} = await stripe.confirmCardPayment(data.data?.client_secret, {
                    payment_method: {
                        card: elements.getElement(CardElement),
                        billing_details: {
                            name: orderData?.name
                        },
                    },
                    setup_future_usage: 'off_session'
                });



                if (error) {
                    setPaymentLoading(false);
                    toast.error(error.message);
                    //alert(paymentResult.error.message);
                
                } else {
                    if (paymentIntent.status === "succeeded") {

                        let data = {
                            trips: orderData,
                            booking_total: totalPrice,
                            payment_type: 'online',
                            usertype: user?.role ? user?.role : null,
                            customer_id: user?.parentDetails ? user?.parentDetails?.id : user?.id ? user?.id : null,
                            created_by: user?.id ? user?.id : null,
                            currency: orderData[0].currency,
                            transactionID: paymentIntent.id,
                            paymentStatus: paymentIntent.status,
                            code,
                            customer_type: user?.customer_type ? user?.customer_type : 0,
                            couponDiscount: couponDiscount,
                            base_total: base_rate,
                            extraCharges: extra,
                            tax: tax,
                            addOn: addOn,
                            agentMarkup: agentMarkup,
                            toll: toll,
                            adminMarkup: adminMarkup,
                            bookingType: bookingType,
                            customer_stripe_id: customerId,
                            payment_method_id: paymentIntent?.payment_method
                        }



                      
                        if (bookingType === "PAL") {
                            dispatch(newBooking(data))
                            //setPaymentLoading(false);
                        }
                        else if (bookingType === "MYTRANSFER") {

                            let myTransData = {
                                customerCountry: orderData[0]?.customerCountry,
                                customerEmail: orderData[0]?.customerEmail,
                                customerFirstName: orderData[0]?.customerFirstName,
                                customerLastName: orderData[0]?.customerLastName,
                                customerPhone: orderData[0]?.customerPhone,
                                destinationType: vehicleAvailability?.dropoffType,
                                key: process.env.REACT_APP_MYTRANSFER_KEY,
                                originType: vehicleAvailability?.pickupType,
                                sessionId: vehicleAvailability?.sessionId,
                                transferId: orderData[0]?.selectedVehicle?.transferId,
                                destinationAddress: orderData[0]?.location_to,
                                originAddress: orderData[0]?.location_from,
                                specialRequirements: orderData[0]?.specialinstructions,
                                extras: orderData[0]?.extras,
                                arrivalLine: orderData[0]?.flightno,
                                arrivalPickUpTime: orderData[0]?.pickupdate,
                                arrivalLocator: 2222,
                            }

                            data.mytransfer = myTransData
                            data.bookingType = bookingType

                     
                            dispatch(newBooking(data))

                            // await orderData?.map(async ord => {
                            //     let myTransData = {
                            //         customerCountry: ord?.customerCountry,
                            //         customerEmail: ord?.customerEmail,
                            //         customerFirstName: ord?.customerFirstName,
                            //         customerLastName: ord?.customerLastName,
                            //         customerPhone: ord?.customerPhone,
                            //         destinationType: vehicleAvailability?.dropoffType,
                            //         key: process.env.REACT_APP_MYTRANSFER_KEY,
                            //         originType: vehicleAvailability?.pickupType,
                            //         sessionId: vehicleAvailability?.sessionId,
                            //         transferId: ord?.selectedVehicle?.transferId,
                            //         destinationAddress: ord?.location_to,
                            //         originAddress: ord?.location_from,
                            //         specialRequirements: ord?.specialinstructions,
                            //         extras: ord?.extras
                            //     }

                            //     await axiosMyT.post(`http://mytransfersapitest-env.jqpvqnc6ft.eu-west-1.elasticbeanstalk.com:5382/${process.env.REACT_APP_MYTRANSFER_KEY}/bookings`, myTransData)
                            //     .then(res => {
                            

                            //     })
                            //     .catch(err => {
                            //     
                            //     })
                            // })

                        }
                        //navigate("/done")
                     
                    }
                }
            })
            .catch(err => {
                setPaymentLoading(false);
            })
    }

    return (
        <>
        <BorderBox title="Card Details">
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 2,
            }}
        >
            <CardElement
                className="card"
                options={{
                    hidePostalCode: true,
                    style: {
                        base: {
                            backgroundColor: "white",
                            
                        },
                        
                    },
                }}
            />
           
        </div>
        </BorderBox>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>
                <Button variant="filled"
                    //disabled={isPaymentLoading}
                    style={{ backgroundColor: isPaymentLoading ? 'gray' : '#333333', borderRadius: 4, padding: 5, height: 30 }} onClick={isPaymentLoading ? null : confirmPayment}>
                    <Typography className='buttonText'>{isPaymentLoading ? "Loading..." : "CONFIRM BOOKING"}</Typography>
                </Button>

            </div>
        </>
    )
}

export default CardDetails