import { CloseRounded } from '@mui/icons-material'
import { Button, Card, CardContent, CardHeader, Divider, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import MainStop from '../screens/Summary/MainStop'
import SubStop from '../screens/Summary/SubStop'

const VehicleCard = ({ booking, data, bookingType, trip, services, deleteBooking, mode }) => {

    const { markupLists } = useSelector(state => state.markup)
    const { enablegett, bookingData } = useSelector(state => state.booking)
    const { user, additionalStops, myTransfer } = useSelector(state => state.auth)



    //let tax = 0, total = 0;

    let total = 0;
    let returnTotal = 0

    if (mode === "current") {
        if (enablegett) {
            let extraRate = 0;
            let rate = 0;
            let adminMarkup = 0;
            let agentmarkup = 0;

            let tax = 0;

            let extras = [];

            rate = parseFloat(data?.price);
            data?.extras.map(ext => {
                if (ext?.extraName === "Child seat" && booking?.childrens > 0) {
                    extraRate += parseFloat(ext?.price) * parseFloat(booking?.childrens);
                    extras.push({ extraId: ext?.extraId, amount: booking?.childrens })
                }
                else if (ext?.extraName === "Booster seat" && booking?.minors > 0) {
                    extraRate += parseFloat(ext?.price) * parseFloat(booking?.minors);
                    extras.push({ extraId: ext?.extraId, amount: booking?.minors })
                }

            })
            if (myTransfer && myTransfer?.value) {
                if (myTransfer?.value_type === "Percentage") {
                    adminMarkup = (rate / 100) * parseFloat(myTransfer?.value)
                }
                else {
                    adminMarkup = myTransfer?.value ? parseFloat(myTransfer?.value) : 0
                }
            }


            if (user?.role === "agent" && markupLists?.type === "fixed") {

                if (markupLists?.status === 1) {
                    agentmarkup = parseFloat(markupLists?.value);
                }

                if (myTransfer?.tax) {
                    tax = ((rate + adminMarkup + extraRate + agentmarkup) / 100) * parseFloat(myTransfer?.tax)
                }

                



                total = rate + adminMarkup + extraRate + agentmarkup + tax;
            }
            else {
                if (user?.role === "agent" && markupLists?.status === 1) {
                    agentmarkup = (rate / 100) * parseFloat(markupLists?.value)
                }

                if (myTransfer?.tax) {
                    tax = ((rate + adminMarkup + extraRate + agentmarkup) / 100) * parseFloat(myTransfer?.tax)
                }

                total = rate + adminMarkup + extraRate + agentmarkup + tax;
            }
        }
        else{
            let addonService = 0;

            services?.map(serv => {
                addonService += Math.ceil(serv?.price)
            })


            let totalWithoutTax = data?.totalWithoutTax + addonService;
            let tax = Math.ceil((totalWithoutTax / 100) * parseFloat(data?.taxPer))

            total = Math.ceil(totalWithoutTax + tax);

            if(booking?.bookingType === "return"){
                let returnTotalWithoutTax = data?.returnTotalWithoutTax + addonService;
                let returnTax = Math.ceil((returnTotalWithoutTax / 100) * parseFloat(data?.taxPer))
                returnTotal = Math.ceil(returnTotalWithoutTax + returnTax);
            }

        }
    }
    else {
        let agentmarkup = 0;
        let adminMarkup = 0
        let tax = 0;
        let returnTax = 0;
        let additionalStop = 0;
        let baserate = 0;
        let extras = data?.extraRate;
        let extraReturn = data?.extrasReturn
        let adminDiscount = 0;
        let extraCharges = data?.extraCharges;
        let extraReturnCharges = data?.extraChargesReturn
        let addonService = 0;
        let extraSeat = data?.extraSeat;
        let toll = data?.tollrate ? Math.ceil(data?.tollrate) : 0;
        addonService = services ? services?.reduce((a, b) => a + parseFloat(b?.price), 0) : 0
        additionalStop = booking?.stops ? additionalStops ? booking?.stops?.length * additionalStops : 0 : 0;
        baserate = booking?.type === "hour" ? (Math.ceil(data?.rate) * Math.ceil(booking?.duration)) + additionalStop : Math.ceil(data?.rate) + additionalStop

        // extraCharges = data?.addonItem?.map(extra => {
        //     if (extra?.rate_type === "percentage") {
        //         extras += booking?.type === "hour" ? (((parseFloat(baserate) / 100) * parseFloat(extra?.rate)) * parseFloat(booking?.duration)) : (parseFloat(baserate) / 100) * parseFloat(extra?.rate)
        //     }
        //     else {
        //         extras += booking?.type === "hour" ? (parseFloat(extra?.rate) * parseFloat(booking?.duration)) : parseFloat(extra?.rate)
        //     }
        // })

        if (user?.role === "agent" && markupLists?.type === "fixed") {
            if (markupLists?.status === 1) {
                agentmarkup = Math.ceil(markupLists?.value);
            }

            if (data?.markUpDetails && data?.markUpDetails?.length > 0) {
                if (data?.markUpDetails[0]?.value_type === "Percentage") {
                    adminMarkup = Math.ceil(((baserate + extras + toll + extraSeat) / 100) * parseFloat(data?.markUpDetails[0]?.value))
                }
                else {
                    adminMarkup = Math.ceil(data?.markUpDetails[0]?.value)
                }
            }
            else if (data?.defaultMarkup) {
                adminMarkup = Math.ceil(((baserate + extras + toll + extraSeat) / 100) * parseFloat(data?.defaultMarkup?.value))
            }

            if(data?.discountsData){
                let ddata = data?.discountsData;
                let totalAmount = baserate + adminMarkup + agentmarkup + toll + extras + extraSeat;
                if(ddata?.discount_type === "percentage"){
                    adminDiscount = Math.ceil((totalAmount/100) * parseFloat(ddata?.discount))
                }
                else{
                    adminDiscount += Math.ceil(ddata?.discount)
                }
            }



            tax = Math.ceil(((baserate + adminMarkup + agentmarkup + extras + addonService + toll - adminDiscount + extraSeat) / 100) * parseFloat(data?.tax?.value))

            returnTax = Math.ceil(((baserate + adminMarkup + agentmarkup + extraReturn + addonService + toll - adminDiscount + extraSeat) / 100) * parseFloat(data?.tax?.value))
            returnTotal = baserate + adminMarkup + agentmarkup + extraReturn + returnTax + addonService + toll - adminDiscount + extraSeat;

            total = baserate + adminMarkup + agentmarkup + extras + tax + addonService + toll - adminDiscount + extraSeat;


        }
        else {
            if (user?.role === "agent" && markupLists?.status === 1) {
                agentmarkup = Math.ceil(((baserate + extras + toll + extraSeat) / 100) * parseFloat(markupLists?.value))
            }

            if (data?.markUpDetails && data?.markUpDetails?.length > 0) {
                if (data?.markUpDetails[0]?.value_type === "Percentage") {
                    adminMarkup = Math.ceil(((baserate + extras + toll + extraSeat) / 100) * parseFloat(data?.markUpDetails[0]?.value))
                }
                else {
                    adminMarkup = Math.ceil(data?.markUpDetails[0]?.value)
                }
            }
            else if (data?.defaultMarkup) {
                adminMarkup = Math.ceil(((baserate + extras + toll + extraSeat) / 100) * parseFloat(data?.defaultMarkup?.value))
            }

            if(data?.discountsData){
                let ddata = data?.discountsData;
                let totalAmount = baserate + adminMarkup + agentmarkup + toll + extras + extraSeat;
                if(ddata?.discount_type === "percentage"){
                    adminDiscount = Math.ceil((totalAmount/100) * parseFloat(ddata?.discount))
                }
                else{
                    adminDiscount += Math.ceil(ddata?.discount)
                }
            }

            tax = Math.ceil(((baserate + adminMarkup + agentmarkup + extras + addonService + toll - adminDiscount + extraSeat) / 100) * parseFloat(data?.tax?.value))

            total = baserate + adminMarkup + agentmarkup + extras + tax + addonService + toll - adminDiscount + extraSeat;

            returnTax = Math.ceil(((baserate + adminMarkup + agentmarkup + extraReturn + addonService + toll - adminDiscount + extraSeat) / 100) * parseFloat(data?.tax?.value))
            returnTotal = baserate + adminMarkup + agentmarkup + extraReturn + returnTax + addonService + toll - adminDiscount + extraSeat;
        }


    }



    return (
        <>
        <Card>
            <CardContent style={{ padding: 0, paddingTop: 2 }}>
                <div style={{ backgroundColor: '#333333', color: '#fff', height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', fontFamily: 'Nunito', paddingLeft: 10, paddingRight: 10, position: 'relative' }}>
                    <div style={{ position: 'absolute', left: 5 }}>
                        Trip {trip}
                    </div>
                    <div>
                        {booking?.type === 'point' ? booking?.location_from?.type === "airport" ? 'Arrival' : booking?.location_to?.type === "airport" ? 'Departure' : 'Point to Point' : 'Hourly'}
                    </div>
                    {deleteBooking && <IconButton onClick={deleteBooking} style={{ position: 'absolute', right: 5 }}>
                        <CloseRounded style={{ color: '#fff' }} />
                    </IconButton>}
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingLeft: 5, borderLeft: '0.5px solid #ccc', borderRight: '0.5px solid #ccc' }}>
                    <div style={{ flex: 0.4 }}>
                        <Typography fontSize={12} fontFamily="Nunito" fontWeight={"bold"}>{data?.vehicledetails?.vehicle_type?.vehicle_type} {bookingData?.duration &&
                            <span> {`${bookingData.duration}Hrs`}</span>}</Typography>
                    </div>

                    <div style={{ flex: 0.3 }}>
                        <Typography fontSize={12} fontFamily="Nunito" fontWeight={"bold"}>{moment(booking?.pickupdate).format("DD-MM-YYYY")}</Typography>
                    </div>
                    <div style={{ flex: 0.3, display: 'flex', justifyContent: 'flex-end', paddingRight: 10 }}>
                        <Typography fontSize={12} fontFamily="Nunito" fontWeight={"bold"}>{moment(booking?.pickupdate).format("hh:mm A")}</Typography>
                    </div>
                </div>
                <Divider />
                <div style={{ borderLeft: '0.5px solid #ccc', borderRight: '0.5px solid #ccc', display: 'flex', flexDirection: 'column' }}>

                    <MainStop
                        address={booking?.location_from?.address}
                        count={1}
                        color="green"
                    />
                    {booking?.type === 'point' &&
                        <div style={{ border: '1px dashed #B6A269', borderLeftWidth: 3, borderRightWidth: 0, borderTopWidth: 0, borderBottomWidth: 0, marginLeft: 10, minHeight: 5 }}>
                            {booking?.stops?.map((stop, index) => (
                                <SubStop
                                    address={stop?.stop_name?.address}
                                    count={index + 2}
                                    color="blue"
                                    index={index}
                                    //removeStop={(index) => removeExtraStop(index)}
                                    stopNote={stop?.stopNote}
                                />
                            ))}
                        </div>}
                    {booking?.location_to?.address &&
                        <MainStop
                            address={booking?.location_to?.address}
                            count={data?.stops ? data?.stops?.length + 1 : 2}
                            color="red"
                        />}
                </div>
                <Divider />
                <div style={{ display: 'flex', paddingLeft: 5, justifyContent: 'center', backgroundColor: '#333333' }}>
                    <Typography fontSize={15} fontFamily="Nunito" color="#fff" fontWeight={"bold"}>FARE : S${mode === "current" ? Math.ceil(total) : Math.ceil(data?.total_price)}</Typography>
                </div>
            </CardContent>
        </Card>
        {(booking?.bookingType === "return" && mode === "current") && <Card>
            <CardContent style={{ padding: 0, paddingTop: 2 }}>
                <div style={{ backgroundColor: '#333333', color: '#fff', height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', fontFamily: 'Nunito', paddingLeft: 10, paddingRight: 10, position: 'relative' }}>
                    <div style={{ position: 'absolute', left: 5 }}>
                    Trip {trip + 1}
                    </div>
                    <div>
                        {booking?.type === 'point' ? booking?.location_to?.type === "airport" ? 'Arrival' : booking?.location_from?.type === "airport" ? 'Departure' : 'Point to Point' : 'Hourly'}
                    </div>
                    {deleteBooking && <IconButton onClick={deleteBooking} style={{ position: 'absolute', right: 5 }}>
                        <CloseRounded style={{ color: '#fff' }} />
                    </IconButton>}
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingLeft: 5, borderLeft: '0.5px solid #ccc', borderRight: '0.5px solid #ccc' }}>
                    <div style={{ flex: 0.4 }}>
                        <Typography fontSize={12} fontFamily="Nunito" fontWeight={"bold"}>{data?.vehicledetails?.vehicle_type?.vehicle_type} {bookingData?.duration &&
                            <span> {`${bookingData.duration}Hrs`}</span>}</Typography>
                    </div>

                    <div style={{ flex: 0.3 }}>
                        <Typography fontSize={12} fontFamily="Nunito" fontWeight={"bold"}>{moment(booking?.returndate).format("DD-MM-YYYY")}</Typography>
                    </div>
                    <div style={{ flex: 0.3, display: 'flex', justifyContent: 'flex-end', paddingRight: 10 }}>
                        <Typography fontSize={12} fontFamily="Nunito" fontWeight={"bold"}>{moment(booking?.returndate).format("hh:mm A")}</Typography>
                    </div>
                </div>
                <Divider />
                <div style={{ borderLeft: '0.5px solid #ccc', borderRight: '0.5px solid #ccc', display: 'flex', flexDirection: 'column' }}>

                    <MainStop
                        address={booking?.location_to?.address}
                        count={1}
                        color="green"
                    />
                    {booking?.type === 'point' &&
                        <div style={{ border: '1px dashed #B6A269', borderLeftWidth: 3, borderRightWidth: 0, borderTopWidth: 0, borderBottomWidth: 0, marginLeft: 10, minHeight: 5, flexDirection: 'column-reverse' }}>
                            {booking?.stops?.map((stop, index) => (
                                <SubStop
                                    address={stop?.stop_name?.address}
                                    count={index + 2}
                                    color="blue"
                                    index={index}
                                    //removeStop={(index) => removeExtraStop(index)}
                                    stopNote={stop?.stopNote}
                                />
                            ))}
                        </div>}
                    {booking?.location_from?.address &&
                        <MainStop
                            address={booking?.location_from?.address}
                            count={data?.stops ? data?.stops?.length + 1 : 2}
                            color="red"
                        />}
                </div>
                <Divider />
                <div style={{ display: 'flex', paddingLeft: 5, justifyContent: 'center', backgroundColor: '#333333' }}>
                    <Typography fontSize={15} fontFamily="Nunito" color="#fff" fontWeight={"bold"}>FARE : S${mode === "current" ? Math.ceil(returnTotal) : Math.ceil(data?.total_price)}</Typography>
                </div>
            </CardContent>
        </Card>}
        </>
    )
}

export default VehicleCard
