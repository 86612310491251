import { CloseOutlined } from '@mui/icons-material';
import { Box, Button, CircularProgress } from '@mui/material';
import React, { useState } from 'react'
import Webcam from "react-webcam";
import customAxios from '../CustomAxios';

const Camera = ({ onCapture, noShowSuccess, tripId, id, closeNoShow }) => {

	const webcamRef = React.useRef(null);
	const [imgSrc, setImageSRC] = useState('')
	const [files, setFiles] = useState([])
	const [loading, setLoading] = useState(false)

	const { innerWidth: width, innerHeight: height } = window;

	const videoConstraints = {
		width: width,
		height: height - 200,
		facingMode: "environment"
	};

	const capture = React.useCallback(
		() => {
			if (files?.length <= 3) {
				const imageSrc = webcamRef.current.getScreenshot();
				//onCapture(imageSrc)
				setFiles(prev => prev ? [...prev, imageSrc] : [imageSrc])
			}

		},
		[webcamRef, files]
	);

	const deleteImage = (index) => {
		let file = files.filter((file, i) => i != index)
		setFiles(file)
	}

	const saveNoShow = async() => {
        const formData = new FormData();
		if (files.length >= 1) {
			const image_list = [];
			for (var i = 0; i < files.length; i++) {
				const photo = files[i];
				formData.append(`image[${i}]`, photo);
			}
		}
		formData.append("trip_id", tripId)
		formData.append("driver_id", id)

        setLoading(true)
        await customAxios.post(`updatenoshow`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })  
        .then(async response => {
            noShowSuccess(response?.data?.data)
        })
        .catch(async error => {
            setLoading(false)
        });
    }

	return (
		<div style={{ display: 'flex',  justifyContent: 'center', flexDirection: 'column' }}>
			<div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
			<Button style={{ backgroundColor: '#B6A269', color: '#fff', marginBottom: 5, height: 30, fontWeight: 'bolder' }} onClick={closeNoShow}>
				X
			</Button>
			</div>
			
			<Webcam
				videoConstraints={videoConstraints}
				ref={webcamRef}
				screenshotFormat="image/jpeg"
				height={480}
				width={350}
			/>
			<Button onClick={capture}>Capture</Button>
			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
				<div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 30, alignItems: 'center', overflowX: 'scroll', width: 300 }}>
					{files && files?.map((file, index) => (
						<div style={{ marginTop: 20, position: 'relative', margin: 10 }}>
							<img src={file} width={100} height={100} />
							<CloseOutlined style={{ position: 'absolute', top: -10, right: -5, fontSize: 20, color: 'red', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => deleteImage(index)} />
						</div>
					))}
					
				</div>
				{loading && <CircularProgress  />}
				{!loading && files && files?.length > 0 && <Button style={{ backgroundColor: '#B6A269', color: '#fff', marginBottom: 5, height: 30 }} onClick={saveNoShow}>
						Update
					</Button>}
			</div>

		</div>
	)
}

export default Camera