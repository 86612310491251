import React, { useState, useEffect } from 'react'
import './style.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import CachedIcon from '@mui/icons-material/Cached';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewTrip from './ViewTrip';
import { getTrip, getTripsByDate } from '../../Redux/actions/tripActions'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Button, Typography, Stack, Tooltip, Snackbar, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import moment from 'moment'
import { DataGrid } from '@mui/x-data-grid';
import CustomDate from '../../components/CustomDate';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFFile from './PDFFile';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Cancel, Edit, LocationOn, Padding } from '@mui/icons-material';
import customAxios from '../../CustomAxios';
import { toast } from 'react-toastify';
import { redirect } from "react-router-dom";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { LOADING } from '../../Redux/constants/bookingConstants';


// function createData(trip_id, passenger_name, vehicle, price, image) {
//     return { trip_id, passenger_name, vehicle, price, image };
//   }

//   const rows = [
//     createData('#62623', 'Sam Witwikky', 'Business Minivan', 92.50,Vehicle1Img),
//     createData('#52623', 'Bran', 'Mercedes Benz', 150,Vehicle2Img),
//     createData('#62654', 'Heiser', 'Audi A8', 190,Vehicle3Img),

//   ];
const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#F6F6F6',
		color: '#333333',
		//boxShadow: theme.shadows[1],
		fontSize: 12,
		fontFamily: 'Nunito',
		fontWeight: '600',
		Padding: 0
	},
}));

const TripList = ({ tripType, fromDate, toDate, editBooking }) => {

	const dispatch = useDispatch();
	const navigate = useNavigate()
	const [id, setId] = useState('')
	const { user } = useSelector(state => state.auth)
	const [datas, setDatas] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const { tripLists, deleteSuccess, error } = useSelector(state => state.trip)
	// const [fromDate, setFromDate] = useState('')
	// const [toDate, setToDate] = useState('')
	const [booktype, setBookType] = useState('')
	const [columnList, setColumnList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [alertPop, setalertPop] = useState(false)
	const [open, setOpen] = useState(false)
	const [active, setActive] = useState(null)





	useEffect(() => {

		if (fromDate && toDate) {
			if (tripType === "upComingTrips") {
				let lists = tripLists?.upComingTrips.filter(onGoing => moment(`${onGoing.pickupdate}`, "YYYY-MM-DD") >= moment(moment(fromDate).format("YYYY-MM-DD"), "YYYY-MM-DD") && moment(`${onGoing.pickupdate}`, "YYYY-MM-DD") <= moment(moment(toDate).format("YYYY-MM-DD"), "YYYY-MM-DD"))

				let palTrips = lists?.map(trip => {
					return {
						...trip,
						type: "PAL"
					}
				})

				let apiLists = tripLists?.upComingApiTrips.filter(onGoing => moment(`${onGoing.pickupdate}`, "YYYY-MM-DD") >= moment(moment(fromDate).format("YYYY-MM-DD"), "YYYY-MM-DD") && moment(`${onGoing.pickupdate}`, "YYYY-MM-DD") <= moment(moment(toDate).format("YYYY-MM-DD"), "YYYY-MM-DD"))

				let myTransferrips = apiLists?.map(trip => {
					return {
						...trip,
						type: "MYTRANSFER"
					}
				})

				let datas = [...palTrips, ...myTransferrips]
				datas.sort(function (a, b) { return moment(`${a.pickupdate} ${a.pickuptime}`, "YYYY-MM-DD hh:mm A") - moment(`${b.pickupdate} ${b.pickuptime}`, "YYYY-MM-DD hh:mm A") });

				setDatas(datas)
			}
			else if (tripType === "completedTrips") {
				let lists = tripLists?.completedTrips.filter(onGoing => moment(`${onGoing.pickupdate}`, "YYYY-MM-DD") >= moment(moment(fromDate).format("YYYY-MM-DD"), "YYYY-MM-DD") && moment(`${onGoing.pickupdate}`, "YYYY-MM-DD") <= moment(moment(toDate).format("YYYY-MM-DD"), "YYYY-MM-DD"))

				let palTrips = lists?.map(trip => {
					return {
						...trip,
						type: "PAL"
					}
				})

				let apiLists = tripLists?.completedApiTrips.filter(onGoing => moment(`${onGoing.pickupdate}`, "YYYY-MM-DD") >= moment(moment(fromDate).format("YYYY-MM-DD"), "YYYY-MM-DD") && moment(`${onGoing.pickupdate}`, "YYYY-MM-DD") <= moment(moment(toDate).format("YYYY-MM-DD"), "YYYY-MM-DD"))

				let myTransferrips = apiLists?.map(trip => {
					return {
						...trip,
						type: "MYTRANSFER"
					}
				})

				let datas = [...palTrips, ...myTransferrips]
				//datas.sort(function (a, b) { return moment(`${a.pickupdate} ${a.pickuptime}`, "YYYY-MM-DD hh:mm A") - moment(`${b.pickupdate} ${b.pickuptime}`, "YYYY-MM-DD hh:mm A") });
				setDatas(datas)
			}
		}
		else {
			if (tripType === "upComingTrips") {
				let pal = tripLists?.upComingTrips.map(trip => {
					return {
						...trip,
						type: "PAL"
					}
				})
				let mytransfer = tripLists?.upComingApiTrips.map(trip => {
					return {
						...trip,
						type: "MYTRANSFER"
					}
				})
				let datas = [...pal, ...mytransfer]
				//datas.sort(function (a, b) { return moment(`${a.pickupdate} ${a.pickuptime}`, "YYYY-MM-DD hh:mm A") - moment(`${b.pickupdate} ${b.pickuptime}`, "YYYY-MM-DD hh:mm A") });
				setDatas(datas);
			}

			if (tripType === "completedTrips") {
				let pal = tripLists?.completedTrips.map(trip => {
					return {
						...trip,
						type: "PAL"
					}
				})
				let mytransfer = tripLists?.completedApiTrips.map(trip => {
					return {
						...trip,
						type: "MYTRANSFER"
					}
				})
				let datas = [...pal, ...mytransfer]
				//datas.sort(function (a, b) { return moment(`${a.pickupdate} ${a.pickuptime}`, "YYYY-MM-DD hh:mm A") - moment(`${b.pickupdate} ${b.pickuptime}`, "YYYY-MM-DD hh:mm A") });
				setDatas(datas)

			}
		}
	}, [fromDate, toDate])

	const sortArray = (array) => {
		if (array.length > 0) {
			let sorted = array.sort(function (a, b) {
				// Turn your strings into dates, and then subtract them
				// to get a value that is either negative, positive, or zero.
				return moment(`${b.pickupdate} ${b.pickuptime}`, "DD-MM-YYYY hh:mm A") - moment(`${a.pickupdate} ${a.pickuptime}`, "DD-MM-YYYY hh:mm A");
			});

			return sorted;
		}

	}


	useEffect(() => {
		if (tripLists) {
			const indexOfLastPost = currentPage * 10;
			const indexOfFirstPost = indexOfLastPost - 10;
			if (tripType === "onGoingTrips") {
				let pal = tripLists?.onGoingTrips.map(trip => {
					return {
						...trip,
						type: "PAL"
					}
				})
				let mytransfer = tripLists?.onGoingApiTrips.map(trip => {
					return {
						...trip,
						type: "MYTRANSFER"
					}
				})
				let datas = [...pal, ...mytransfer]
				//datas.sort(function (a, b) { return moment(`${a.pickupdate} ${a.pickuptime}`, "YYYY-MM-DD hh:mm A") - moment(`${b.pickupdate} ${b.pickuptime}`, "YYYY-MM-DD hh:mm A") });
				setDatas(datas)
			}
			else if (tripType === "upComingTrips") {
				//const currentPosts = (tripLists?.upComingTrips || []).slice(indexOfFirstPost, indexOfLastPost);
				// if(tripLists?.upComingTrips?.length > 0){
				// 	setDatas(sortArray(tripLists?.upComingTrips));
				// }
				let pal = tripLists?.upComingTrips.map(trip => {
					return {
						...trip,
						type: "PAL"
					}
				})
				let mytransfer = tripLists?.upComingApiTrips.map(trip => {
					return {
						...trip,
						type: "MYTRANSFER"
					}
				})
				let datas = [...pal, ...mytransfer]
				//datas.sort(function (a, b) { return moment(`${a.pickupdate} ${a.pickuptime}`, "YYYY-MM-DD hh:mm A") - moment(`${b.pickupdate} ${b.pickuptime}`, "YYYY-MM-DD hh:mm A") });
				setDatas(datas);
			}
			else {
				//const currentPosts = (tripLists?.completedTrips || []).slice(indexOfFirstPost, indexOfLastPost);
				let pal = tripLists?.completedTrips.map(trip => {
					return {
						...trip,
						type: "PAL"
					}
				})
				let mytransfer = tripLists?.completedApiTrips.map(trip => {
					return {
						...trip,
						type: "MYTRANSFER"
					}
				})
				let datas = [...pal, ...mytransfer]
				//datas.sort(function (a, b) { return moment(`${a.pickupdate} ${a.pickuptime}`, "YYYY-MM-DD hh:mm A") - moment(`${b.pickupdate} ${b.pickuptime}`, "YYYY-MM-DD hh:mm A") });
				setDatas(datas)
			}



		}

	}, [tripLists, tripType])
	// useEffect(() => {
	//     setBookType(JSON.parse(datas?.row?.booking_data))
	//   }, [tripLists])

	function getVehicleType(params) {
		if (params?.row?.type === "PAL") {
			return `${params.row?.vehicletype?.vehicle_type}`;
		}
		else {
			return `${params.row?.transportName}`;
		}
	}

	function getFullPrice(params) {
		return parseFloat(params?.row?.totalprice).toFixed(2);
	}

	function getcreatedDate(params) {
		return moment(params.row.created_at, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY hh:mm A");
	}



	function getBookingType(params) {

		if (params?.row?.type === "PAL") {
			let data = JSON.parse(params.row.booking_data);
			if (data?.location_from?.type === 'airport') {
				return 'Arrival';
			}
			else if (data?.location_to?.type === 'airport') {
				return 'Departure';
			}
			else if (data?.type === 'point') {
				return 'Point to Point';
			}
			else {
				return 'Hourly';
			}
		}
		else {
			return "Point to Point"
		}


	}


	const cancelTrip = (trip) => {
		setActive(trip)
		setOpen(true)
	}


	const confirmCancel = async() => {
		setOpen(false)
		dispatch({
			type: LOADING,
			payload: true
		})
        try {
            const res = await customAxios.post('cancelBooking', { trip_id: active?.id });
            if(res?.status === 200){
				
               
                toast.error("Trip cancelled successfully")
				let params = {
					parent_id : user?.parentDetails ? user?.parentDetails?.id : user?.id,
					child_id : user?.id
				}
				
				//let id = user?.id
				dispatch(getTrip(params))
                
            }
            else{
                toast.error("Unable to cancel trips. Please try again lator")
            }
        } catch (error) {
            toast.error("Unable to cancel trips. Please try again lator")
        }
        finally{
			setActive(null)
            dispatch({
				type: LOADING,
				payload: false
			})
        }
	}




	useEffect(() => {
		if (user?.role === 'customer') {
			setColumnList(columnsB2C)
		} else {
			setColumnList(columnsB2B)
		}
	}, [user])


	const columnsB2C = [
		{
			field: 'id',
			headerName: 'STATUS-REF NO.',
			width: 130,
			renderCell: (params) => {
				return (
					<div style={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
						{`${params?.row?.type === 'MYTRANSFER' ? params?.row?.status : params?.row?.tripstatus?.name} - ${params?.row?.id}`}
						{(params?.row?.baby_seats > 0 || params?.row?.booster_seats > 0 || params?.row?.childrens > 0 || params?.row?.minors > 0) && <div style={{ height: 20, width: 20, borderRadius: 10, backgroundColor: 'blue', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<label style={{ fontSize: 12, fontWeight: 'bold', color: '#fff' }}>E</label>
						</div>}
					</div>
				)
			}
		},

		{
			field: 'pickupdate',
			headerName: 'LOCAL PU DATE & TIME',
			width: 150,
			renderCell: (params) => {
				return <Typography fontSize={12} >{`${moment(params.row.pickupdate, "YYYY-MM-DD").format("DD-MM-YYYY")} ${params.row.pickuptime}`}</Typography>;
			}
		},
		{
			field: 'flighttime',
			headerName: 'FLIGHT/CRUISE',
			width: 110,
			renderCell: (params) => {
				if(params?.row?.pickup_loc_type === "Cruise" && moment(params?.row?.disembarkment,"HH:mm").isValid()){
					return(
						<Typography fontSize={12} style={{ cursor: 'pointer' }}>{moment(params?.row?.disembarkment,"HH:mm").format("hh:mm A")}</Typography>
					)
				}
				else if(params?.row?.arrival === "Arrival" && moment(params?.row?.flighttime, "hh:mm A").isValid()){
					return(
						<Typography fontSize={12} style={{ cursor: 'pointer' }}>{params?.row?.flighttime}</Typography>
					)
				}
				else if(params?.row?.arrival === "Departure" && moment(params?.row?.departure_flight_date).isValid()){
					return(
						<Typography fontSize={12} style={{ cursor: 'pointer' }}>{moment(params?.row?.departure_flight_date).format("hh:mm A")}</Typography>
					)
				}
				else{
					return "";
				}
				
			}

		},
		{
			field: 'arrival',
			headerName: 'SERVICE TYPE',
			width: 100,
			//valueGetter: getBookingType
		},
		{
			field: 'customer',
			headerName: 'CUSTOMER',
			width: 130,
			valueGetter: (params) => (params.row?.customer_details?.name)
		},
		{
			field: 'name',
			headerName: 'PASSENGER NAME',
			width: 170,
			renderCell: (params) => {
				return (<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Typography fontSize={12}>{params?.row?.name}</Typography>
					<Typography fontSize={12}>{params?.row?.email}</Typography>
					<Typography fontSize={12}>{params?.row?.mobile}</Typography>
				</div>)
			}
		},
		// {
		// 	field: 'created_by',
		// 	headerName: 'CREATED BY',
		// 	width: 200,
		// 	renderCell: (params) => {
		// 		return (<div style={{ display: 'flex', flexDirection: 'column' }}>
		// 			<Typography fontSize={12} style={{ cursor: 'pointer' }} >{params?.row?.creator_details?.name}</Typography>

		// 		</div>)
		// 	}
		// },
		{
			field: 'booked by',
			headerName: 'BOOKED BY',
			width: 130,
			renderCell: (params) => {
				return (<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Typography fontSize={12} style={{ cursor: 'pointer' }} >{params?.row?.name}</Typography>
					<Typography fontSize={12} >{params?.row?.email}</Typography>
					<Typography fontSize={12} >{params?.row?.mobile}</Typography>
				</div>)
			}
		},
		{
			field: 'location_from',
			headerName: 'TRIP',
			width: 300,
			renderCell: (params) => {
				return (<div style={{ display: 'flex', flexDirection: 'column' }}>
					<div style={{ display: 'flex' }}>
						<LocationOn style={{ width: 15, height: 15, color: 'green' }} />
						<Typography fontSize={12}>{params?.row?.location_from}</Typography>
					</div>
					{params?.row?.location_to &&
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<LocationOn style={{ width: 14, height: 15, color: 'red' }} />
							<Typography fontSize={12}>{params?.row?.location_to}</Typography>
						</div>}
				</div>)
			}
		},
		{
			field: 'vehicle',
			headerName: 'VEHICLE',
			width: 140,
			renderCell: (params) => {
				return (<div style={{ display: 'flex', flexDirection: 'column' }}>
					{params?.row?.driver_status === 1 && <div><Typography fontFamily={'Nunito'} fontSize={12}>{params?.row?.vehicledetail?.brand} {params?.row?.vehicledetail?.vehicle_number}</Typography>
						<Typography fontSize={12}>{params?.row?.driver?.name}</Typography></div>}
				</div>)

			}
			// (`${params?.row?.vehicledetail?.brand}(${params?.row?.vehicledetail?.vehicle_number})`)
		},
		{
			field: 'vehicletype',
			headerName: 'VEHICLE TYPE',
			width: 130,
			valueGetter: getVehicleType
		},

		{
			field: 'price',
			headerName: 'TOTAL PRICE (S$)',
			width: 100,
			renderCell: (params) => {
				return <Typography fontSize={12} >S${parseFloat(params?.row?.totalprice).toFixed(2)}</Typography>
			}
		},
		{
			field: 'paymentdetails',
			headerName: 'Payment Status',
			width: 120,
			renderCell: (params) => {
				return <div>
					<Typography fontSize={12} >{params?.row?.paymentdetails?.payment_type === "manual" ? params?.row?.payment_status === "1" ? "Completed" : "Pending" : params?.row?.paymentdetails?.status}</Typography>
					{params?.row?.RefundAmount > 0 && <Typography fontSize={10} >{`(Refund: S$ ${params?.row?.RefundAmount})`}</Typography>}
				</div>
			}
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'ACTIONS',
			width: 260,
			cellClassName: 'actions',
			renderCell: (params) => {
				return (
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						{(tripType === "upComingTrips" && editBooking) && <LightTooltip title="Edit Trip" placement="left">
							<Button
								variant="text"
								color="primary"
								type="submit"
								cursor="pointer"
								onClick={() => editTrip(params?.row)}
							>
								<Edit style={{ color: '#333333', height: 40, padding: 0 }} />
							</Button>
						</LightTooltip>}
						{(tripType === "upComingTrips" && editBooking) && <LightTooltip title="Cancel Trip" placement="left">
							<Button
								variant="text"
								color="primary"
								type="submit"
								cursor="pointer"
								onClick={() => cancelTrip(params?.row)}
							>
								<Cancel style={{ color: '#333333', height: 40, padding: 0 }} />
							</Button>
						</LightTooltip>}
						<LightTooltip title="Download Invoice" placement="left">
							<Button
								variant="text"
								color="primary"
								type="submit"
								cursor="pointer"
								onClick={() => downlodeTripInvoice(params?.row?.id)}
							>
								<CloudDownloadIcon style={{ color: '#333333', height: 40, padding: 0 }} />
							</Button>
						</LightTooltip>
						<LightTooltip title="View Trip Details" placement="left">
							<Button
								sx={{ display: 'flex', alignItems: 'center' }}
								variant="text"
								color="primary"
								type="submit"
								style={{ padding: 5 }}
								onClick={() => viewTripDetails(params)}
								cursor="pointer"
							>
								<VisibilityIcon style={{ color: '#333333', height: 40 }} color='#333333' />
							</Button>
						</LightTooltip>
					</div>
				);
			},
		},
	];


	//B2B COLUMUN......................................


	const flightDetails = (params) => {
		if (params?.row?.arrival === "Arrival") {
			return (
				<Typography fontSize={12} style={{ cursor: 'pointer' }}>{params?.row?.flighttime}</Typography>
			)
		}
		else if (params?.row?.arrival === "Departure") {
			return (
				<Typography fontSize={12} style={{ cursor: 'pointer' }}>{moment(params?.row?.departure_flight_date).format("hh:mm A")}</Typography>
			)
		}
		else if (params?.row?.pickup_loc_type === "Cruise") {
			return (
				<Typography fontSize={12} style={{ cursor: 'pointer' }}>{params?.row?.disembarkment}</Typography>
			)
		}
	}


	const editTrip = (data) => {
	
		if (data?.type === "MYTRANSFER") {
			setalertPop(true)
		} else {
			let tripDate = moment(data?.offsetdatetime, "YYYY-MM-DD HH:mm:ss");
			let today = moment().utcOffset(0)
			let diff = moment.duration(tripDate.diff(today)).asHours();
			if(diff > 24){
				setId(data?.id);
			}
			else{
				toast.error("You can make trip modifications only up to 24 hours prior to the scheduled departure. Please contact our administrator")
			}
		}


		//navigate(`/edittrip/${id}`, { replace: true })
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setalertPop(false);
		setOpen(false)
	};


	useEffect(() => {
		if (id) {
			navigate(`/edittrip/${id}`, { replace: true })
		}
	}, [id])


	const columnsB2B = [
		{
			field: 'id',
			headerName: 'STATUS-REF NO.',
			width: 130,
			renderCell: (params) => {
				return (
					<div style={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
						{`${params?.row?.type === 'MYTRANSFER' ? params?.row?.status : params?.row?.tripstatus?.name} - ${params?.row?.id}`}
						{(params?.row?.baby_seats > 0 || params?.row?.booster_seats > 0 || params?.row?.childrens > 0 || params?.row?.minors > 0) && <div style={{ height: 20, width: 20, borderRadius: 10, backgroundColor: 'blue', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<label style={{ fontSize: 12, fontWeight: 'bold', color: '#fff' }}>E</label>
						</div>}
					</div>
				)
			}
		},

		{
			field: 'pickupdate',
			headerName: 'LOCAL PU DATE & TIME',
			width: 150,
			renderCell: (params) => {
				return <Typography fontSize={12} style={{ cursor: 'pointer' }}	>{`${moment(params.row.pickupdate, "YYYY-MM-DD").format("DD-MM-YYYY")} ${params.row.pickuptime}`}</Typography>;
			}
		},
		{
			field: 'flighttime',
			headerName: 'FLIGHT/CRUISE',
			width: 110,
			renderCell: (params) => {
				if(params?.row?.pickup_loc_type === "Cruise" && moment(params?.row?.disembarkment,"HH:mm").isValid()){
					return(
						<Typography fontSize={12} style={{ cursor: 'pointer' }}>{moment(params?.row?.disembarkment, "HH:mm").format("hh:mm A") }</Typography>
					)
				}
				else if(params?.row?.arrival === "Arrival" && moment(params?.row?.flighttime,"hh:mm A").isValid()){
					return(
						<Typography fontSize={12} style={{ cursor: 'pointer' }}>{params?.row?.flighttime}</Typography>
					)
				}
				else if(params?.row?.arrival === "Departure" && moment(params?.row?.departure_flight_date).isValid()){
					return(
						<Typography fontSize={12} style={{ cursor: 'pointer' }}>{moment(params?.row?.departure_flight_date).format("hh:mm A")}</Typography>
					)
				}
				else{
					return "";
				}
				
			}

		},
		{
			field: 'arrival',
			headerName: 'SERVICE TYPE',
			width: 100,
			//valueGetter: getBookingType
		},
		{
			field: 'name',
			headerName: 'PASSENGER NAME',
			width: 170,
			renderCell: (params) => {
				return (<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Typography fontSize={12} style={{ cursor: 'pointer' }} >{params?.row?.name}</Typography>
					<Typography fontSize={12} >{params?.row?.email}</Typography>
					<Typography fontSize={12} >{params?.row?.mobile}</Typography>
				</div>)
			}
		},
		{
			field: 'booked_by',
			headerName: 'BOOKED BY',
			width: 130,
			renderCell: (params) => {
				return (<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Typography fontSize={12} style={{ cursor: 'pointer' }} >{params?.row?.creator_details?.name}</Typography>

				</div>)
			}
		},
		// {
		// 	field: 'created_by',
		// 	headerName: 'CREATED BY',
		// 	width: 200,
		// 	renderCell: (params) => {
		// 		return (<div style={{ display: 'flex', flexDirection: 'column' }}>
		// 			<Typography fontSize={12} style={{ cursor: 'pointer' }} >{params?.row?.creator_details?.name}</Typography>

		// 		</div>)
		// 	}
		// },
		{
			field: 'location_from',
			headerName: 'TRIP',
			width: 300,
			renderCell: (params) => {
				return (<div style={{ display: 'flex', flexDirection: 'column' }}>
					<div style={{ display: 'flex' }}>
						<LocationOn style={{ width: 15, height: 15, color: 'green' }} />
						<Typography fontSize={12}>{params?.row?.location_from}</Typography>
					</div>
					{params?.row?.location_to &&
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<LocationOn style={{ width: 14, height: 15, color: 'red' }} />
							<Typography fontSize={12}>{params?.row?.location_to}</Typography>
						</div>}
				</div>)
			}
		},
		{
			field: 'vehicle',
			headerName: 'VEHICLE',
			width: 140,
			renderCell: (params) => {
				return (<div style={{ display: 'flex', flexDirection: 'column' }}>
					{params?.row?.driver_status === 1 && <div><Typography fontFamily={'Nunito'} fontSize={12}>{params?.row?.vehicledetail?.brand} {params?.row?.vehicledetail?.vehicle_number}</Typography>
						<Typography fontSize={12}>{params?.row?.driver?.name}</Typography></div>}
				</div>)

			}
			// (`${params?.row?.vehicledetail?.brand}(${params?.row?.vehicledetail?.vehicle_number})`)
		},
		{
			field: 'vehicletype',
			headerName: 'VEHICLE TYPE',
			width: 130,
			valueGetter: getVehicleType
		},

		{
			field: 'price',
			headerName: 'TOTAL PRICE (S$)',
			width: 100,
			renderCell: (params) => {
				return <Typography fontSize={12} style={{ cursor: 'pointer' }} >S${parseFloat(params?.row?.totalprice).toFixed(2)}</Typography>
			}
		},
		{
			field: 'paymentdetails',
			headerName: 'PAYMENT STATUS',
			width: 120,
			renderCell: (params) => {
				return <div>
					<Typography fontSize={12} >{params?.row?.paymentdetails?.status}</Typography>
					{params?.row?.RefundAmount > 0 && <Typography fontSize={10} >{`(Refund: S$ ${params?.row?.RefundAmount})`}</Typography>}
				</div>
			}
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'ACTIONS',
			width: 260,
			cellClassName: 'actions',
			renderCell: (params) => {
				return (
					<>
						{(tripType === "upComingTrips" && editBooking) && <LightTooltip title="Edit Trip" placement="left">
							<Button
								variant="text"
								color="primary"
								type="submit"
								cursor="pointer"
								onClick={() => editTrip(params?.row)}
							>
								<Edit style={{ color: '#333333', height: 40, padding: 0 }} />
							</Button>
						</LightTooltip>}
						{(tripType === "upComingTrips" && editBooking) && <LightTooltip title="Cancel Trip" placement="left">
							<Button
								variant="text"
								color="primary"
								type="submit"
								cursor="pointer"
								onClick={() => cancelTrip(params?.row)}
							>
								<Cancel style={{ color: '#333333', height: 40, padding: 0 }} />
							</Button>
						</LightTooltip>}
						<LightTooltip title="Download Invoice" placement="top">
							<Button
								variant="text"
								color="primary"
								type="submit"
								cursor="pointer"
								onClick={() => downlodeTripInvoice(params?.row?.id)}
							>
								<CloudDownloadIcon style={{ color: '#333333', height: 40, padding: 0 }} />
							</Button>
						</LightTooltip>
						<LightTooltip title="View Trip Details" placement="top">
							<Button
								variant="text"
								color="primary"
								type="submit"
								sx={{ display: 'flex', alignItems: 'center' }}
								onClick={() => viewTripDetails(params)}
								cursor="pointer"

							>
								<VisibilityIcon style={{ color: '#333333', height: 40 }} color='#333333' />
							</Button>
						</LightTooltip>
					</>
				);
			},
		},
	];


	const downlodeTripInvoice = async (id) => {
		setLoading(true);
		await customAxios.post(`generateinvoice`, {
			trip_id: id
		})
			.then(async response => {

				const url = response.data.data;
				const link = document.createElement('a');
				link.href = url;
				link.target = '_blank'
				link.setAttribute('download', `invoice_${id}.pdf`); //or any other extension
				document.body.appendChild(link);
				link.click();
				setLoading(false);
			})
			.catch(async error => {
				setLoading(false);
				toast.error(error);
			});


	}

	const viewTripDetails = (params) => {
		setSelected(params);
		setViewModalShow(true);
	}


	const [viewModalShow, setViewModalShow] = useState(false);
	const [selected, setSelected] = useState(null)
	const location = useLocation();

	const schema = yup.object({
		fromDate: yup.string(),
		toDate: yup.string()
	}).required();


	const { register, handleSubmit, control, formState: { errors } } = useForm({
		resolver: yupResolver()
	});


	const onSubmit = (fromDate, toDate) => {
		let userId = user.id
		dispatch(getTripsByDate(fromDate, toDate, userId))
	};


	return (
		<div>
			{/* {tripType !== "onGoingTrips" && <form onSubmit={handleSubmit(onSubmit)}>
				<Typography style={{ paddingBottom: 10, paddingTop: 10, color: '#333333', fontWeight: 'bold', fontSize: 12 }}>Search With Date</Typography>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }} >
					<div style={{ paddingTop: 5, paddingBottom: 10, paddingRight: 10 }}>
						{tripType == "upComingTrips" &&
							<CustomDate label={"From Date"} changeDate={(value) => setFromDate(value)} minDate={moment().toDate()} />
						}
						{tripType == "completedTrips" &&
							<CustomDate label={"From Date"} changeDate={(value) => setFromDate(value)} />
						}
					</div>
					<div style={{ paddingTop: 5, paddingBottom: 10 }}>
						{tripType == "upComingTrips" &&
							<CustomDate label={"To Date"} changeDate={(value) => setToDate(value)} minDate={moment().toDate()} />
						}
						{tripType == "completedTrips" &&
							<CustomDate label={"To Date"} changeDate={(value) => setToDate(value)} />
						}
					</div>
				</div>
			</form>} */}

			<TableContainer component={Paper} style={{ marginTop: 20 }}>
				<div style={{ height: 450, width: "100%", border: 0 }}>
					<DataGrid
						rows={datas ? datas : []}
						columns={columnList}
						pageSize={10}
						rowsPerPageOptions={[10]}
						components={{
							NoRowsOverlay: () => (
								<Stack height="100%" alignItems="center" justifyContent="center">
									No records found
								</Stack>
							)
						}}
						isRowSelectable={(params) => {
							setSelected(params)
							setViewModalShow(true)
						}}
					/>
				</div>
				{alertPop && <Snackbar open={alertPop} autoHideDuration={3000} onClose={handleClose}>
					<Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
					Please contact Administrator to edit trip!
					</Alert>
				</Snackbar>}
				{viewModalShow && <ViewTrip openModal={viewModalShow} closeModal={() => setViewModalShow(false)} trips={selected} />}
				<Dialog
					open={open}
					onClose={() => setOpen(false)}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						{"Warning?"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Are you sure want to cancel Trip?.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}>Cancel</Button>
						<Button onClick={confirmCancel} autoFocus>
							Yes
						</Button>
					</DialogActions>
				</Dialog>
				{/* <Table sx={{ minWidth: 660 }} aria-label="simple table" >
        <TableHead>
          <TableRow>
            <TableCell className='tablecell' align="center">Trip ID</TableCell>
            <TableCell className='tablecell' align="center">Passenger Name</TableCell>
            <TableCell className='tablecell' align="center">Vehicle</TableCell>
            <TableCell className='tablecell' align="center">Total Price ( S$ )</TableCell>
            <TableCell className='tablecell' align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {datas && datas.map((trip, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell className='tablevalue' align="center">
                {trip.paymentdetails.tripID}
              </TableCell>
              <TableCell className='tablevalue' align="center">{trip.name}</TableCell>
              <TableCell className='tablevalue' align="center">{trip.vehicletype.vehicle_type}</TableCell>
              <TableCell className='tablevalue' align="center">{trip.paymentdetails.base_rate}</TableCell>
              <TableCell className='tablevalue' align="center"><CloudDownloadIcon/>&nbsp;<CachedIcon/>&nbsp;<VisibilityIcon onClick={()=>viewTripDetails(trip)} cursor="pointer"/></TableCell>
            </TableRow>
          ))}
        </TableBody>
       
        {viewModalShow && <ViewTrip openModal={viewModalShow} closeModal={()=> setViewModalShow(false)}  trips={selected}  />}
      </Table> */}

			</TableContainer>
		</div>
	)
}

export default TripList
