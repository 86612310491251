import React, { useEffect, useState } from 'react'
import './booking.scss'
import { Button, Grid, Typography, FormGroup, ToggleButtonGroup, ToggleButton, Snackbar, Alert, CircularProgress, Box } from '@mui/material'
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles';
import { addVipBooking } from '../../Redux/actions/bookingActions';
import { RESET_ERROR } from '../../Redux/constants/bookingConstants';
import PlacesInput from '../../components/PlacesInput';
import DateInput from '../../components/DateInput';
import moment from 'moment';
import PhoneInputs from '../../components/PhoneInputs';
import DateTimeInput from '../../components/DateTimeInput';
import GooglePlaceInput from '../../components/GooglePlaceInput';
import customAxios from '../../CustomAxios';
const VipMeetGreet = () => {
	const dispatch = useDispatch();
	const [alignment, setAlignment] = React.useState('arrival');
	const [open, setOpen] = React.useState(false);
	const [clear, setClear] = React.useState(false);
	const { user, loading } = useSelector(state => state.auth)

	const [suggList, setSuggList] = useState([])

	useEffect(() => {
        searchAddress()
    }, [])

    const searchAddress = async () => {
        await customAxios.get(`search/address/${user?.id}`)
        .then(async response => {

            let sugList = [];
            response?.data?.data?.map((item, index) => {
                let place = JSON.parse(item?.geometry)
                sugList.push({
                    ...place,
                    description: place?.address
                })
            })
            setSuggList(sugList)

        })
        .catch(async error => {

        });

    }

	const schema = yup.object({
		location_from: yup.mixed().required("Pick up location is required"),
		location_to: yup.mixed().required("Drop off location is required"),
		pickupdate: yup.string().required("Pickup date & time required"),
		flightno: yup.string().required("Flight number is required"),
		contact_number: yup.string().required("Contact number is required").max(30),
		email: yup.string().email('Must be a valid email').max(30).required('Email is required'),
	}).required();



	const { register, handleSubmit, setValue, reset, control, formState: { errors }, getValues, setError } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			flightno: '',
			email: '',
			location_from: null,
			location_to: null,
			pickupdate: ''
		},
		mode:'onChange'
	});

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};
	const { newVipBooking, error } = useSelector(state => state.booking)




	useEffect(() => {
		if (error) {
			dispatch({
				type: RESET_ERROR
			})
		}
		if (newVipBooking) {
			setOpen(true)
			dispatch({
				type: RESET_ERROR
			})
			reset()
			setClear(true)
		}

	}, [error, newVipBooking])

	const StyledToggle = styled((props) => <ToggleButton disableRipple {...props} />)(
		({ theme }) => ({
			textTransform: 'none',
			fontFamily: 'nunito',
			borderRadius: 0,
			fontSize: theme.typography.pxToRem(12),
			marginRight: theme.spacing(0),
			color: '#333333',
			border: '0px',
			backgroundColor: '#F6F6F6',
			'&.Mui-selected': {
				color: '#fff',
				backgroundColor: '#333333'
			},
			'&.Mui-selected:hover': {
				color: '#fff',
				backgroundColor: '#B6A269'
			},
			padding: 5,
			height: 30
		}),
	);
	const handleChange = (event, newAlignment) => {
		setAlignment(newAlignment);
	};

	const onSubmit = (data) => {



		let input = {
			type: alignment,
			pickup_location: data?.location_from?.address,
			dropoff_location: data?.location_to?.address,
			pickup_date_time: moment(data?.pickupdate).format("DD-MM-YYYY hh:mm A"),
			flightno: data?.flightno,
			email: data?.email,
			contact_number: data?.contact_number
		}



		dispatch(addVipBooking(input))

	};


	const setFrom = (value) => {
		setValue("location_from", value)
		setError("location_from", '')
	}

	const setTo = (value) => {
		setValue("location_to", value)
		setError("location_from", '')
	}



	return (

		<Grid container sx={{ flexGrow: 1, alignItems: 'center', }} marginTop={1}>
			<Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="success" sx={{ width: '98%' }}>
					Your booking has been received.We will contact you soon
				</Alert>
			</Snackbar>
			<Box width="100%">
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={2} flexGrow={1} paddingBottom={2}>
						<Grid item xs={12} md={2} lg={1.5} sx={{ paddingTop: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }} borderRight={{ xs: 0 }} borderColor='#F6F6F6'>
							<ToggleButtonGroup
								color="primary"
								value={alignment}
								exclusive
								onChange={handleChange}
								aria-label="Platform"
								style={{ height: 40, alignItems: 'center' }}
							>
								<StyledToggle value="arrival" className='toggleText'
								>Arrival</StyledToggle>

								<StyledToggle value="departure" className='toggleText'>Departure</StyledToggle>
							</ToggleButtonGroup>
							{/* <InputField fieldName="type" control={control} error= { errors.type } defaultValue={alignment} width='10ch' fontSize="14px"/> */}
						</Grid>
						<Grid item container spacing={2} xs={12} md={10} >
							<Grid item xs={12} md={4} lg={2.5}   >
								<GooglePlaceInput
									control={control}
									fieldName={"location_from"}
									label={'Pick Up Location'}
									setLocation={setFrom}
									error={errors?.location_from?.message}
									suggList={suggList}
								/>
								{/* <PlacesInput
									mode={'vip'}
									setClear={setClear}
									clear={clear}
									fieldLabel="Pick Up Location"
									fieldName="location_from"
									control={control}
									error={errors.location_from}
									fontSize="14px"
									//width='90%'
									onPlaceChange={(value) => {
										setValue("location_from", value)
										setError("location_from", '')
									}}
								/> */}
							</Grid>
							<Grid item xs={12} md={4} lg={2.5} sx={{ flexGrow: 1 }}>
								<GooglePlaceInput
									control={control}
									fieldName={"location_to"}
									label={'Drop Off Location'}
									setLocation={setTo}
									error={errors?.location_to?.message}
									suggList={suggList}
								/>
								{/* <PlacesInput
									mode={'vip'}
									setClear={setClear}
									clear={clear}
									fieldLabel="Drop Off Location"
									fieldName="location_to"
									control={control}
									error={errors.location_to}
									fontSize="14px"
									onPlaceChange={(value) => {
										setValue("location_to", value)
										setError("location_from", '')
									}} /> */}
							</Grid>
							<Grid item xs={12} md={4} lg={2} xl={1.5} sx={{ flexGrow: 1 }}>
								<DateInput
								    todaycheck={true}
									mode={'hour'}
									fieldLabel="Pick Up Date & Time"
									fieldName="pickupdate"
									control={control}
									error={errors.pickupdate}
									fieldType="datetime"
									minDate={moment().toDate()}
									fontSize="12px"
									changeDate={(value) => setValue("pickupdate", value)}
								/>
							</Grid>
							<Grid item xs={12} md={4} lg={1} sx={{ flexGrow: 1 }} >
								<InputField
									fieldLabel="Flight No."
									fieldName="flightno"
									control={control}
									error={errors.flightno}
									width='86%'
									fontSize="14px"
									placeholder="Flight No"
								/>
							</Grid>
							<Grid item xs={12} md={4} lg={2} sx={{ flexGrow: 1 }} >
								<InputField
									fieldLabel="Email Address"
									fieldName="email"
									control={control}
									error={errors.email}
									width='86%'
									fontSize="14px"
									placeholder="Email Address"
								/>
							</Grid>

							{/* <Divider orientation="vertical" color='white' flexItem/> */}

							<Grid item xs={12} md={4} lg={2} xl={2} sx={{ flexGrow: 1 }} >
								<PhoneInputs
									fieldLabel="Contact Number"
									fieldName="contact_number"
									control={control}
									error={errors.contact_number}
									fontSize="14px"
								/>
							</Grid>
						</Grid>
					</Grid>

					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '-35px' }}>
						<Button
							type="submit"
							variant="filled"
							disabled={loading}
							style={{ backgroundColor: '#333333', borderRadius: 4, padding: 5, height: 30 }}
						>
							{loading ? 'loading..' : <Typography className='buttonText'>Submit</Typography>}
						</Button>
					</div>
				</form>

			</Box>
		</Grid>

	)
}

export default VipMeetGreet
